import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import HeroSlider from "../components/HeroSlider";
import "../index.css";
import PROJECTS from "../staticData/projects.json";
import { useAuth } from "../context/AuthContext";

const Home = () => {
  const { palette, ...theme } = useTheme();
  const { nfts } = useAuth();
  const isMobile = useMediaQuery("(max-width:599px)");
  return (
    <Box
      sx={{
        position: isMobile ? "relative" : "absolute",
        height: isMobile ? "auto" : "80vh",
        marginLeft: isMobile ? 0 : "20px",
        width: "96%",
        padding: 0,
        marginTop: isMobile ? 3 : 1,
        marginBottom: isMobile ? 0 : 2,
        minHeight: "300px",
        alignContent: isMobile ? "start" : "end",
        [theme.breakpoints.down("sm")]: {
          mb: 10,
        },
      }}
    >
      {nfts?.length > 0 && <HeroSlider projects={nfts} heading="Your assets" />}
    </Box>
  );
};

export default Home;
