import React, { createContext, useState, useContext, useEffect } from "react";
import instance from "../components/axios";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "../components";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [nfts, setNfts] = useState([]);
  const [nft, setNft] = useState({});
  const login = async (address, chainId) => {
    setLoading(true);
    try {
      await instance
        .get("/wallet/login", {
          params: {
            address,
            chainId,
          },
        })
        .then((response) => {
          localStorage.setItem("address", address);
          doLogin(response.data);
        });
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const ownNft = async (token, id) => {
    try {
      let bearer = null;
      if (token) {
        bearer = `Bearer ${token}`;
      }
      if (nfts?.length > 0) {
        const nft = nfts?.find((e) => e.tokenId == id);

        if (!nft && id) return navigate("/");
        return setNft(nft);
      }
      await instance
        .get("/own", {
          headers: {
            Authorization: bearer,
          },
        })
        .then((response) => {
          if (response?.data?.nfts) {
            setNfts(response?.data?.nfts);
            const nft = response?.data?.nfts?.find((e) => e.tokenId == id);
            setLoading(false);
            if (!nft && id) return navigate("/");
            return setNft(nft);
          }
        });
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const doLogin = async (params) => {
    setLoading(true);
    await instance
      .post("/verify/login", params)
      .then(async (response) => {
        const { token, user } = response.data;
        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(user)); // Save user data to local storage
        instance.get("/user").then((response) => {});
        setUser(user);
        await ownNft(token, "");
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user"); // Remove user data from local storage
    setUser(null);
    setNfts([]);
    setNft({});
  };

  const verifyToken = async (id) => {
    const token = localStorage.getItem("token");
    const storedUser = localStorage.getItem("user");

    setLoading(true);
    try {
      setUser(JSON.parse(storedUser));
      const response = await instance.get("/user");
      setUser(response.data);
      await ownNft(token, id);
      localStorage.setItem("user", JSON.stringify(response.data));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (
        (error.response && error.response?.status === 401) ||
        error.response?.status === 403
      ) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthContext.Provider
      value={{ loading, user, login, logout, verifyToken, ownNft, nfts, nft }}
    >
      {loading && <Loader />}
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
