import { useTheme } from "@emotion/react";
import { Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import PROJECTS from "../staticData/projects.json";
import { useAuth } from "../context/AuthContext";
const Minting = () => {
  const { palette, ...theme } = useTheme();
  const { id } = useParams();
  const isMobile = useMediaQuery("(max-width:599px)");
  const navigate = useNavigate();
  const { nft } = useAuth();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        alignContent: "center",
        height: "85vh",
        overflowY: "scroll",
        marginInline: "auto",
        width: "95vw",
        flexDirection: "column",
        [theme.breakpoints.down("sm")]: {
          height: "60vh",
        },
      }}
    >
      {nft?.image && (
        <>
          {isMobile ? (
            <img
              width={"90%"}
              alt="nft-thumb"
              style={{
                borderRadius: "20px",
                marginTop: "100px",
                border: "1px solid #94E16140",
              }}
              src={nft?.image}
            />
          ) : (
            <img
              height={"60%"}
              alt="nft-thumb"
              style={{ borderRadius: "50px", border: "2px solid #fff" }}
              src={nft?.image}
            />
          )}
        </>
      )}
      <Box
        sx={{
          cursor: "pointer",
          margin: "0.5vw",
          [theme.breakpoints.down("sm")]: {
            margin: "16px",
          },
        }}
        onClick={() =>
          // handleMint()
          navigate(`/${id}`)
        }
      >
        <Box
          sx={{
            width: "18vw",
            border: "1px solid #fff",
            borderRadius: "50px",
            height: "50px",
            minWidth: "200px",
            alignItems: "center",
            backdropFilter: "blur(6px)",
            backgroundColor: "#1565C0 ",
            display: "flex",
            justifyContent: "center",
            background: "",
            color: "white",
            "&:hover": {
              background: "rgba(0, 0, 0, 0.1)",
            },
            [theme.breakpoints.down("sm")]: {
              width: "90%",
              marginBottom: "25px",
              maxWidth: "180px",
              minWidth: "160px",
              height: "45px",
              borderRadius: "8px",
            },
          }}
        >
          <Typography
            sx={{
              fontWeight: "700",
              fontFamily: "var(--font-f_r)",
              fontSize: "1vw",
              textTransform: "capitalize",
              textAlign: "center",
              [theme.breakpoints.down("lg")]: {
                fontSize: "16px",
              },
              [theme.breakpoints.down("sm")]: {
                fontSize: "12px",
              },
            }}
          >
            Claim your 3D model
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Minting;
