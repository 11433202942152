import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Slider from "react-slick";
import "./HeroSlider.css";

const HeroSlider = ({ projects = [], heading }) => {
  const [slider, setSlider] = useState(null);
  const { palette, ...theme } = useTheme();
  const isMobile = useMediaQuery("(max-width:599px)");
  // useEffect(() => {
  //   const timeoutId = setTimeout(() => {
  //     next();
  //     previous();
  //   }, 500);

  //   return () => clearTimeout(timeoutId);
  // }, [slider]);

  const navigate = useNavigate();

  const next = () => {
    if (slider) {
      slider.slickNext();
    }
  };

  const previous = () => {
    if (slider) {
      slider.slickPrev();
    }
  };

  const afterChange = (currentSlide) => {
    if (currentSlide === projects.length - 1) {
      previous();
    }
  };
  const winWidth = window.innerWidth;

  const getResposiveSize = (max) => {
    if (projects.length > max) {
      return max;
    }
    if (projects.length === 7) {
      return 6;
    } else if (projects.length === 6) {
      return 6;
    } else if (projects.length === 5) {
      return 5;
    } else if (projects.length === 4) {
      return 4;
    } else if (projects.length === 3) {
      return 3;
    } else if (projects.length === 2) {
      return 2;
    } else {
      return 1;
    }
  };
  const getSlidesToShow = () => {
    if (winWidth > 1500) {
      return getResposiveSize(7);
    } else if (winWidth > 1200 && winWidth <= 1500) {
      return getResposiveSize(6);
    } else if (winWidth > 1000 && winWidth <= 1200) {
      return getResposiveSize(5);
    } else if (winWidth > 700 && winWidth <= 1000) {
      return getResposiveSize(4);
    } else if (winWidth > 500 && winWidth <= 700) {
      return getResposiveSize(3);
    } else if (winWidth <= 500) {
      return getResposiveSize(1);
    } else {
      return getResposiveSize();
    }
  };

  const screen = window.innerWidth;
  const getlenght = () => {
    if (projects.length === 7) {
      return "100%";
    } else if (projects.length === 6) {
      return "100%";
    } else if (projects.length === 5) {
      return `${screen / 1.25}px`;
    } else if (projects.length === 4) {
      return `${screen / 1.5}px`;
    } else if (projects.length === 3) {
      return `${screen / 1.7}px`;
    } else if (projects.length === 2) {
      return `${screen / 2.8}px`;
    } else if (projects.length === 1) {
      return `${screen / 4}px`;
    } else {
      return "100%";
    }
  };
  const getSlidesToScroll = () => {
    return 1;
  };
  var settings = {
    adaptiveHeight: true,
    infinite: true,
    speed: 1000,
    slidesToShow: getSlidesToShow(),
    slidesToScroll: getSlidesToScroll(),
    arrows: false,
    className: "slick-slider",
    autoplay: false,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 3840,
        settings: {
          slidesToShow: getSlidesToShow(),
          slidesToScroll: getSlidesToScroll(),
        },
      },
      {
        breakpoint: 2200,
        settings: {
          slidesToShow: getSlidesToShow(),
          slidesToScroll: getSlidesToScroll(),
        },
      },
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: getSlidesToShow(),
          slidesToScroll: getSlidesToScroll(),
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: getSlidesToShow(),
          slidesToScroll: getSlidesToScroll(),
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: getSlidesToShow(),
          slidesToScroll: getSlidesToScroll(),
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: getSlidesToShow(),
          slidesToScroll: getSlidesToScroll(),
        },
      },
    ],
    afterChange: afterChange,
  };
  const responsiveWidth = () => {
    if (winWidth > 1500) {
      return "12.7vw";
    } else if (winWidth > 1000 && winWidth <= 1500) {
      return "15vw";
    } else if (winWidth > 700 && winWidth <= 1000) {
      return "18vw";
    } else if (winWidth <= 700) {
      return "27vw";
    } else {
      return "12.7vw";
    }
  };
  const Projects = [...projects].map((project, i) => (
    <Box
      key={i}
      onClick={() => {
        navigate(`${project.tokenId}`);
      }}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        background: "rgba(0, 0, 0, 0.1)",
        backdropFilter: "blur(8px)",
        m: isMobile ? "6px" : "auto",
        width: isMobile ? "44%" : responsiveWidth(),
        alignItems: "flex-start",
        // margin: "5px 0",
        cursor: "pointer",
        maxWidth: {
          md: "300px",
          xs: "300px",
          sm: "250px",
          lg: "250px",
          xl: "320px",
        }, // Fixed syntax here
        minHeight: "12vh",
        height: "25vh",
        transition: "all .4s ease-in-out",
        borderRadius: isMobile ? "10px" : "0.7vw",
        "&:hover": {
          transform: "scale(1.05)",
          ".text-banner-pro": {
            color: "#dece82",
          },
        },
        "& img": {
          paddingTop: "0",
          marginTop: "0",
          width: isMobile ? "100%" : "inherit",
          zIndex: 10000,
          position: "relative",
          height: "78%",
          objectFit: "cover",
          objectPosition: "top",
          borderRadius: isMobile ? "10px" : "0.4vw",
        },
        margin: { xl: "3vh", md: "2vh" },
      }}
    >
      <img src={project.thumbnailUrl} alt={`project-${i}`} />
      <Box display="flex" p={1}>
        <Typography
          className="text-banner-pro"
          sx={{
            padding: "1px 8px",
            margin: 0,
            fontWeight: "700",
            textTransform: "capitalize",
            fontFamily: "var(--font-f_r)",
            color: palette.grey[200],
            [theme.breakpoints.down("sm")]: {
              fontSize: "22px",
            },
            fontSize: { xl: "32px", lg: "25px" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "left",
          }}
        >
          {`# ${project?.tokenId}`}
        </Typography>
      </Box>
    </Box>
  ));

  return (
    <Box>
      <Grid container>
        <Grid item xs={isMobile ? 12 : 6}>
          <Box>
            <Typography
              sx={{
                fontWeight: "bolder",
                textTransform: "capitalize",
                fontFamily: "var(--font-f_r)",
                margin: "0 13px",
                fontSize: "26px",
                alignItems: "center",
                paddingLeft: "20px",
                color: palette.grey[200],
                [theme.breakpoints.down("sm")]: {
                  fontSize: "20px",
                },
                fontSize: { xl: "50px", md: "30px" },
              }}
            >
              {heading} :
            </Typography>
          </Box>
        </Grid>
        {!isMobile && (
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "2em",
            }}
          >
            <Box>
              <IconButton onClick={previous} color="primary">
                <ChevronLeftIcon size={isMobile ? "12px" : "1vw"} />
              </IconButton>
              <IconButton onClick={next} color="primary">
                <ChevronRightIcon size={isMobile ? "12px" : "1vw"} />
              </IconButton>
            </Box>
          </Grid>
        )}
      </Grid>
      {isMobile ? (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {Projects}
        </Box>
      ) : (
        <Box width={getlenght()}>
          <Slider
            className="slick-slider"
            ref={(c) => setSlider(c)}
            {...settings}
          >
            {Projects}
          </Slider>
        </Box>
      )}
    </Box>
  );
};

export default HeroSlider;
