import { useTheme } from "@emotion/react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import { Header } from "../components";
import Menus from "../components/Menus";
import projectsData from "../staticData/projects.json";
const NftModelDetails = () => {
  const { palette, ...theme } = useTheme();
  const { id } = useParams();
  const specificImageData = projectsData.find((project) => project.slug === id);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box
      sx={{
        zIndex: 1000,
        // background:
        //   "linear-gradient(152.91deg, #000000 2.86%, rgba(0, 0, 0, 0.67) 61.45%)",
        backgroundPosition: "bottom",
        backgroundSize: "cover",
        backgroundImage:
          "url('https://ishrostorage.blob.core.windows.net/container-3d/Group%20232-min%20(1)%20(1)%20(1).png')",
        height: "100vh",
      }}
    >
      <Header hamburger={true} showBackButton={true} />
      <Box
        sx={{
          height: "100%",
          marginBlock: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "70vh",
            paddingInline: "40px",
          }}
        >
          <Menus />

          <Box
            sx={{
              border: "1px solid #94E161",
              width: "65vw",
              display: "flex",
              flexDirection: "column",
              // marginTop: "240px",
              alignItems: "center",
              height: "100%",
              backdropFilter: "blur(10px)",
              justifyContent: "center",
              minWidth: "450px",
              padding: 1,
              borderRadius: "15px",
            }}
          >
            <Typography
              className="menus-text"
              sx={{
                fontWeight: "700",
                textTransform: "capitalize",
                fontFamily: "var(--font-f_r)",
                fontSize: "2vw",
                // marginTop: "0.9vw",
                color: "#fff",
                opacity: 0.7,
                [theme.breakpoints.down("sm")]: {
                  fontSize: "14px",
                },
              }}
            >
              {specificImageData.name}
            </Typography>
            <Typography
              className="menus-text"
              sx={{
                fontWeight: "700",
                textTransform: "capitalize",
                fontFamily: "var(--font-f_r)",
                fontSize: "2vw",
                // marginTop: "0.9vw",
                color: "#fff",
                opacity: 0.7,
                [theme.breakpoints.down("sm")]: {
                  fontSize: "14px",
                },
              }}
            >
              # {specificImageData.nft[0].name}
            </Typography>
            <img
              style={{ width: "25vw" }}
              alt="nft-thumb"
              src={specificImageData.nft[0].image_url}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NftModelDetails;
