import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import React from "react";
import { MdArrowBackIosNew } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const BackButton = ({ title = "Back", onClick = () => {} }) => {
  const { palette, ...theme } = useTheme();
  const navigate = useNavigate();
  const path = window.location.pathname
    .split("/")
    .slice(0, window.location.pathname.split("/").length - 1)
    .join("/");
  return (
    <Box
      onClick={() => navigate(path === "" ? "/" : path)}
      display="flex"
      sx={{
        cursor: "pointer",
        zIndex: 10200,
        transition: "all .4s ease-in-out",
        ".back-button-text": {},
      }}
      width="4.5vw"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          color: "#fff",
        }}
      >
        <MdArrowBackIosNew size={24} />
        <Typography
          className="back-button-text"
          sx={{
            textTransform: "capitalize",
            fontFamily: "var(--font-f_r)",
            marginLeft: "4px",
            userSelect: "none",
            fontWeight: "800",
            fontSize: "24px",
            color: "#fff",
            [theme.breakpoints.down("sm")]: {
              fontSize: "22px",
            },
          }}
        >
          {title}
        </Typography>
      </Box>
    </Box>
  );
};

export default BackButton;
