import { useTheme } from "@emotion/react";
import { Box, Snackbar, Typography, useMediaQuery } from "@mui/material";
import * as React from "react";
import { BiDownload } from "react-icons/bi";
import { downloadFileFromUrl, getFileFromUrl, showToast } from "../../../utils";
import DashboardLayout from "../layout";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import "./snackbar.css";
function File({ style = {} }) {
  const { palette, ...theme } = useTheme();
  const { id } = useParams();
  const { nft } = useAuth();
  const [render, setRender] = React.useState("");
  const getRenders = async () => {
    const res = await getFileFromUrl(id, "png");
    setRender(res);
  };
  React.useEffect(() => {
    getRenders();
  }, []);
  const [loading, setLoading] = React.useState(false);
  const [progress, setProgress] = React.useState(0);

  const RenderRiggedItem = ({ data, margin = false, renders = false }) => {
    return (
      <Box sx={{ marginTop: margin ? "4vw" : "" }}>
        <Typography
          sx={{
            fontWeight: "700",
            fontFamily: "var(--font-f_r)",
            fontSize: "1.2vw",
            textAlign: "start",
            width: "100%",
            marginLeft: "16px",
            textTransform: "capitalize",
            color: palette.dark.text,
            [theme.breakpoints.down("lg")]: {
              fontSize: "16px",
            },
            [theme.breakpoints.down("sm")]: {
              fontSize: "12px",
            },
          }}
        >
          {data?.title}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          {data.items.map((mod) => {
            return (
              <Box
                sx={{
                  cursor: "pointer",
                  border: "1px solid #474747",
                  padding: "15px",
                  height: "25vh",
                  width: "13vw",
                  margin: "10px",
                  backdropFilter: "blur(20px)",
                  background: "rgba(0,0,0,0.2)",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "15px",
                  [theme.breakpoints.down("sm")]: {
                    width: "45%",
                    padding: "10px",
                    margin: "4px",
                  },
                  "&. img": {
                    background: "red",
                  },
                }}
              >
                <img
                  style={{
                    width: mod.image?.includes("svg") ? "62%" : "75%",
                    marginBottom: mod.image?.includes("svg") ? "-20px" : "20px",
                  }}
                  src={renders ? render : mod.image}
                />
                <Typography
                  onClick={async () => {
                    if (loading) return;
                    setLoading(true);
                    try {
                      const error = await downloadFileFromUrl(
                        id,
                        mod.file,
                        setProgress
                      );
                      setProgress(0);
                      setLoading(false);
                      if (error) {
                        alert(error);
                      }
                    } catch (e) {
                      setProgress(0);
                      setLoading(false);
                    }
                  }}
                  sx={{
                    fontWeight: "700",
                    fontFamily: "var(--font-f_r)",
                    fontSize: "1vw",
                    textAlign: "center",
                    // marginBottom: "-20px",
                    width: "100%",
                    alignItems: "center",
                    textTransform: "capitalize",
                    color: palette.dark.text,
                    [theme.breakpoints.down("lg")]: {
                      fontSize: "16px",
                    },
                    [theme.breakpoints.down("sm")]: {
                      fontSize: "12px",
                    },
                  }}
                >
                  {mod.downloadText}
                  <BiDownload
                    color={palette.primary[100]}
                    style={{
                      marginLeft: "5px",
                      marginBottom: "-5px",
                    }}
                    size={25}
                  />
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    );
  };
  const isMobile = useMediaQuery("(max-width:599px)");
  const RiggedModel = () => {
    const riggedData = [
      {
        title: "File format available :",
        items: [
          {
            name: "FBX",
            image: "https://storage.googleapis.com/3d-container/ATS/FBX.png",
            file: "fbx",
            downloadText: "Download",
          },
          {
            name: "glb",
            image: "https://storage.googleapis.com/3d-container/ATS/GLB.png",
            file: "glb",
            downloadText: "Download",
          },
          // {
          //   name: "vrm",
          //   image:
          //     "https://ishrostorage.blob.core.windows.net/container-3d/vrmlFile.png",
          // },
        ],
      },
    ];

    return (
      <Box
        sx={{
          width: "100%",
          padding: 3,
          [theme.breakpoints.down("sm")]: {
            padding: 1,
          },
        }}
      >
        <RenderRiggedItem data={riggedData[0]} />
      </Box>
    );
  };
  const Renders = () => {
    const riggedData = [
      {
        title: "File format available :",
        items: [
          {
            name: "Low-Resolution",
            image: nft?.image,
            file: "png",
            downloadText: "Low-Resolution",
          },
          {
            name: "High-Resolution",
            image: nft?.image,
            file: "png",
            downloadText: "High-Resolution",
          },
          // {
          //   name: "vrm",
          //   image:
          //     "https://ishrostorage.blob.core.windows.net/container-3d/vrmlFile.png",
          // },
        ],
      },
    ];

    return (
      <Box
        sx={{
          width: "100%",
          padding: 3,
          [theme.breakpoints.down("sm")]: {
            padding: 1,
          },
        }}
      >
        <RenderRiggedItem data={riggedData[0]} renders />
      </Box>
    );
  };
  const layoutOptions = [
    {
      name: "Rigged Model",
      children: RiggedModel(),
    },
    {
      name: "Renders",
      children: Renders(),
    },
  ];
  return (
    <>
      <DashboardLayout layoutOptions={layoutOptions} title={"Meta/Game/VR"} />
      <div
        style={{
          width: "100%",
          display: "flex",
          position: "absolute",
          bottom: isMobile ? 200 : 20,
          right: isMobile ? 0 : 20,
          justifyContent: isMobile ? "center" : "end",
        }}
      >
        <div className={`snackbar ${loading ? "show" : ""}`}>
          {`Fetching File ${progress.toFixed(1)} MB`}
        </div>
      </div>
    </>
  );
}

export default File;
