import { useTheme } from "@emotion/react";
import { Avatar, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";

import { enqueueSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { Copy, ThreeD, TwoD } from "../assets/images/icons/Icons";
import { animation, file, library, still } from "../staticData/images";
import PROJECTS from "../staticData/projects.json";
import { useAuth } from "../context/AuthContext";
const NftDetails = () => {
  const { palette, ...theme } = useTheme();
  const { id } = useParams();
  const { nft } = useAuth();

  const owner = localStorage.getItem("address");

  const [traits, setTraits] = useState([
    {
      value: "TRAITS",
      floorAskPrices: ["FLOOR"],
      onSaleCount: "LISTED",
      tokenCount: "SUPPLY",
    },
  ]);
  const [thred, set3d] = useState(false);
  const isMobile = useMediaQuery("(max-width:599px)");

  const getNftMetaData = async () => {
    try {
      const options = {
        method: "GET",
        headers: {
          accept: "*/*",
          "x-api-key": process.env.REACT_APP_RESERVOIR_KEY,
        },
      };

      fetch(
        `https://api.reservoir.tools/collections/0x7CB90567a118cd9F6CA326067A0813b289bdCb54/attributes/explore/v5?tokenId=${id}`,
        options
      )
        .then((response) => response.json())
        .then((response) => {
          setTraits([...traits, ...response?.attributes]);
        })
        .catch((err) => console.error(err));
    } catch (err) {
      console.log(err);
    }
  };
  const navigate = useNavigate();

  useEffect(() => {
    getNftMetaData();
  }, []);
  const RenderImage = ({ name, image, goto }) => {
    return (
      <Box
        onClick={() => navigate(goto)}
        sx={{
          display: "flex",
          flexDirection: "column",
          cursor: "pointer",
          width: "90%",
          height: "24%",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "0.8vw",
          padding: "0.5vw",
          "&:hover": {
            background: "#1565C0",
          },
          [theme.breakpoints.down("sm")]: {
            width: "23%",
            height: "50px",
            borderRadius: "10px",
            p: 1,
          },
        }}
      >
        <img style={{ width: isMobile ? "20px" : "2vw" }} src={image} />
        <Typography
          sx={{
            verticalAlign: "center",
            fontFamily: "var(--font-f_r)",
            textTransform: "capitalize",
            fontSize: "0.8vw",
            color: "#fff",
            [theme.breakpoints.down("sm")]: {
              fontSize: "10px",
            },
          }}
        >
          {name}
        </Typography>
      </Box>
    );
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        alignContent: "center",
        height: "75vh",
        marginInline: "auto",
        width: "95vw",
        mt: "5vh",
        [theme.breakpoints.down("sm")]: {
          mt: "20px",
          // overflow: "scroll",
          height: "auto",
          flexDirection: "column",
        },
      }}
    >
      <Box
        sx={{
          width: "10%",
          minWidth: "130px",
          display: "flex",
          alignItems: "center",
          height: "100%",
          flexDirection: "column",
          margin: "auto",
          boxShadow:
            "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
          background: "rgba(0, 0, 0, 0.1)",
          backdropFilter: "blur(8px)",
          justifyContent: "flex-start",
          p: 1,
          borderRadius: "0.8vw",
          [theme.breakpoints.down("sm")]: {
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
            background: "rgba(0, 0, 0, 0.3)",
            height: "80px",
            borderRadius: "10px",
            backdropFilter: "blur(20px)",
            border: "none",
            mt: "90px",
            mb: 3,
          },
        }}
      >
        <RenderImage image={animation} name="Animations" goto="animation" />
        <RenderImage image={still} name="Still Pose" goto="stillPose" />
        <RenderImage image={file} name="File" goto="file" />
      </Box>
      <Box
        sx={{
          width: "85%",
          display: "flex",
          alignItems: "center",
          height: "100%",
          margin: "auto",
          // backdropFilter: "blur(10px)",
          justifyContent: "space-between",
          minWidth: "450px",
          padding: 1,
          background: "rgba(0, 0, 0, 0.1)",
          backdropFilter: "blur(8px)",
          boxShadow:
            "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
          borderRadius: "0.8vw",
          [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            width: "100%",
            height: "auto",
            padding: 0,
            minWidth: "300px",
            border: "none",
          },
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
          },
        }}
      >
        <Box
          sx={{
            width: "30%",
            height: "98%",
            borderRadius: "20px",
            padding: "20px",
            minWidth: "350px",
            [theme.breakpoints.down("sm")]: {
              width: "96%",
              padding: "6px",
              borderRadius: "8px",
            },
          }}
        >
          {isMobile && (
            <Typography
              className="menus-text"
              sx={{
                fontWeight: "700",
                textTransform: "capitalize",
                fontFamily: "var(--font-f_r)",
                fontSize: "1.5vw",
                // marginTop: "0.9vw",
                color: palette.dark.text,
                opacity: 0.7,
                [theme.breakpoints.down("sm")]: {
                  fontSize: "20px",
                },
              }}
            >
              ATS
            </Typography>
          )}
          <Typography
            className="menus-text"
            sx={{
              fontWeight: "700",
              textTransform: "capitalize",
              fontFamily: "var(--font-f_r)",
              fontSize: "1.5vw",
              // marginTop: "0.9vw",
              color: "#fff",
              [theme.breakpoints.down("sm")]: {
                fontSize: "20px",
                mb: 1,
              },
            }}
          >
            # {nft?.tokenId}
          </Typography>

          <Box
            sx={{
              borderRadius: "38px",
              background: "rgba(0, 0, 0, 0.15)",
              backdropFilter: "blur(8px)",
            }}
          >
            {nft?.image && (
              <>
                <Box sx={{ position: "absolute", m: 2 }}>
                  <Box
                    onClick={() => set3d(false)}
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    <TwoD color={thred ? "#fff" : "#94E161"} />
                  </Box>
                  <Box
                    onClick={() => set3d(true)}
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    <ThreeD color={thred ? "#94E161" : "#fff"} />
                  </Box>
                </Box>
                <img
                  style={{
                    width: "100%",
                    objectFit: "contain",
                    borderTopLeftRadius: "40px",
                    borderTopRightRadius: "40px",
                    overflow: "hidden",
                  }}
                  src={thred && nft?.image ? nft?.image : nft?.image}
                />
              </>
            )}

            <Box
              display="flex"
              sx={{
                flexWrap: "wrap",
                width: "96%",
                flexDirection: "row",
                my: 2,
                mx: 1,
                [theme.breakpoints.down("sm")]: {
                  flexDirection: "column",
                },
                [theme.breakpoints.down("lg")]: {
                  flexDirection: "column",
                },
              }}
              justifyContent="space-between"
            >
              <Box pb={2} display="flex" alignItems="center">
                <Avatar
                  sx={{
                    width: "26px",
                    height: "26px",
                  }}
                  src={nft?.image}
                />
                <Typography
                  sx={{
                    fontWeight: "700",
                    textTransform: "capitalize",
                    fontFamily: "var(--font-f_r)",
                    fontSize: "14px",
                    marginLeft: "10px",
                    color: palette.primary[100],
                    [theme.breakpoints.down("sm")]: {
                      fontSize: "12px",
                    },
                    [theme.breakpoints.down("lg")]: {
                      fontSize: "12px",
                    },
                  }}
                >
                  {owner &&
                    owner.slice(0, 4) +
                      "....." +
                      owner.slice(owner?.length - 4, owner?.length)}
                </Typography>
              </Box>
              <Box
                pb={2}
                justifyContent="space-between"
                display="flex"
                alignItems="center"
                sx={{
                  cursor: "pointer",
                  width: "50%",
                  [theme.breakpoints.down("sm")]: {
                    width: "100%",
                  },
                }}
              >
                <img
                  style={{ marginInline: "4px" }}
                  onClick={() =>
                    window.open(
                      `https://blur.io/asset/0x7cb90567a118cd9f6ca326067a0813b289bdcb54/${id}`,
                      "_blank"
                    )
                  }
                  src="https://storage.googleapis.com/3d-container/NFTDetailPageIcons/blur.svg"
                />
                <img
                  style={{ marginInline: "4px" }}
                  onClick={() =>
                    window.open(
                      `https://looksrare.org/collections/0x7cb90567a118cd9f6ca326067a0813b289bdcb54/${id}`,
                      "_blank"
                    )
                  }
                  src="https://storage.googleapis.com/3d-container/NFTDetailPageIcons/looks%20rare.svg"
                />
                <img
                  style={{ marginInline: "4px" }}
                  onClick={() =>
                    window.open(
                      `https://opensea.io/assets/ethereum/0x7cb90567a118cd9f6ca326067a0813b289bdcb54/${id}`,
                      "_blank"
                    )
                  }
                  src="https://storage.googleapis.com/3d-container/NFTDetailPageIcons/opensea.svg"
                />

                <img
                  style={{ marginInline: "4px" }}
                  onClick={() =>
                    window.open(
                      `https://x2y2.io/eth/0x7cb90567a118cd9f6ca326067a0813b289bdcb54/${id}`,
                      "_blank"
                    )
                  }
                  src="https://storage.googleapis.com/3d-container/NFTDetailPageIcons/x2y2.svg"
                />
                <img
                  width="30px"
                  style={{ marginInline: "4px" }}
                  onClick={() =>
                    window.open(
                      `https://magiceden.io/collections/ethereum/0x7cb90567a118cd9f6ca326067a0813b289bdcb54?evmItemDetailsModal=1~0x7cb90567a118cd9f6ca326067a0813b289bdcb54~${id}`,
                      "_blank"
                    )
                  }
                  src="https://storage.googleapis.com/3d-container/ATS/magiceden.svg"
                />
              </Box>
            </Box>
          </Box>
          <Box
            mt={1}
            display="flex"
            minHeight="50px"
            height="3vw"
            alignItems="center"
            justifyContent="center"
            sx={{
              background: palette.dark.bg,
              cursor: "pointer",
              borderRadius: "0.5vw",

              [theme.breakpoints.down("sm")]: {
                borderRadius: "10px",
              },
            }}
            onClick={() => {
              navigator?.clipboard?.writeText(window.location.href);
              enqueueSnackbar("Copied!", {
                variant: "success",
              });
            }}
          >
            <Typography
              sx={{
                fontFamily: "var(--font-f_r)",
                fontSize: "1vw",
                mr: 2,
                fontWeight: "800",
                color: palette.dark.text,
                [theme.breakpoints.down("sm")]: {
                  fontSize: "14px",
                },
              }}
            >
              Copy
            </Typography>
            <Copy color={palette.dark.text} />
          </Box>
        </Box>
        <Box
          sx={{
            width: "68%",
            height: "98%",
            [theme.breakpoints.down("sm")]: {
              width: "96%",
              my: 1,
              height: "100%",
            },
            [theme.breakpoints.down("md")]: {
              width: "96%",
            },
          }}
        >
          <Box
            sx={{
              borderRadius: "10px",
              padding: "14px",
              mt: 2,
              height: "95%",
              [theme.breakpoints.down("sm")]: {
                mt: 1,
                padding: "2px",
                height: "100%",
              },
            }}
          >
            <Box
              sx={{
                borderRadius: "10px",
                padding: "14px",
                mt: 2,
                overflowY: "scroll",
                height: { xl: "89%", lg: "83%" },
                maxHeight: "68vh",
                [theme.breakpoints.down("sm")]: {
                  paddingBottom: "60px",
                },
              }}
            >
              <Box>
                {traits?.length > 0 ? (
                  <>
                    {traits
                      .filter(
                        (trait) =>
                          !String(trait?.traitVal)?.includes("http") &&
                          trait?.key !== "ApeCoin Staked"
                      )
                      // ?.slice(0, 12)
                      .map((trait, i) => {
                        return (
                          <Box
                            key={i + "trait"}
                            display="flex"
                            my={2}
                            sx={{
                              background: i > 0 ? "#eabe59" : "",
                              borderRadius: "10px",
                            }}
                            p={1}
                            borderBottom="1px solid #95959529"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Box width="20%">
                              <Typography
                                sx={{
                                  fontFamily:
                                    i > 0
                                      ? "var(--font-i_r)"
                                      : "var(--font-f_r)",
                                  fontWeight: "500",
                                  fontSize: i > 0 ? "0.6vw" : "0.8vw",
                                  textAlign: "center",
                                  color: i > 0 ? "#000" : "#fff",
                                  [theme.breakpoints.down("sm")]: {
                                    fontSize: "14px",
                                  },
                                }}
                              >
                                {trait?.key}
                              </Typography>
                              <Typography
                                sx={{
                                  fontFamily:
                                    i > 0
                                      ? "var(--font-i_r)"
                                      : "var(--font-f_r)",
                                  fontWeight: "700",
                                  textAlign: "center",
                                  fontSize: i > 0 ? "0.6vw" : "0.8vw",
                                  color: i > 0 ? "#000" : "#fff",
                                  [theme.breakpoints.down("sm")]: {
                                    fontSize: "14px",
                                  },
                                }}
                              >
                                {trait?.value}
                              </Typography>
                            </Box>
                            <Typography
                              sx={{
                                width: "10%",
                                fontFamily:
                                  i > 0 ? "var(--font-i_r)" : "var(--font-f_r)",
                                fontWeight: "700",
                                textAlign: "center",
                                fontSize: i > 0 ? "0.6vw" : "0.8vw",
                                color: i > 0 ? "#000" : "#fff",
                                [theme.breakpoints.down("sm")]: {
                                  fontSize: "14px",
                                },
                              }}
                            >
                              {trait?.floorAskPrices[0]}
                            </Typography>
                            <Typography
                              sx={{
                                width: "10%",
                                fontFamily:
                                  i > 0 ? "var(--font-i_r)" : "var(--font-f_r)",
                                fontWeight: "700",
                                fontSize: i > 0 ? "0.6vw" : "0.8vw",
                                textAlign: "center",
                                color: i > 0 ? "#000" : "#fff",
                                [theme.breakpoints.down("sm")]: {
                                  fontSize: "14px",
                                },
                              }}
                            >
                              {trait?.onSaleCount}
                            </Typography>
                            {/* <Typography
                                  sx={{
                                    width: "10%",
                                    fontFamily: i>0?"var(--font-i_r)": "var(--font-i_r)",
                                    fontSize: i>0? "0.8vw":"1vw",
                                    color: i>0?"#000":"#000",fff.breakpoints.down("sm")]: {
                                      fontSize: "14px",
                                    },
                                  }}
                                >
                                  {trait?.listings}%
                                </Typography> */}
                            <Typography
                              sx={{
                                width: "10%",
                                fontFamily:
                                  i > 0 ? "var(--font-i_r)" : "var(--font-f_r)",
                                fontWeight: "700",
                                textAlign: "center",
                                fontSize: i > 0 ? "0.6vw" : "0.8vw",
                                color: i > 0 ? "#000" : "#fff",
                                [theme.breakpoints.down("sm")]: {
                                  fontSize: "14px",
                                },
                              }}
                            >
                              {trait?.tokenCount}
                            </Typography>
                          </Box>
                        );
                      })}
                  </>
                ) : (
                  <Typography
                    sx={{
                      fontFamily: "var(--font-f_r)",
                      fontSize: "1vw",
                      color: "#fff",
                      mt: 2,
                      textAlign: "center",
                      [theme.breakpoints.down("sm")]: {
                        fontSize: "14px",
                      },
                    }}
                  >
                    No Data Available
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NftDetails;
