import React, { useEffect, useState } from "react";

import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  Dialog,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { RxCross2 } from "react-icons/rx";
import HomeButton from "../HomeButton";
export const ShareDialog = ({
  mode,
  openShare,
  setOpenShare,
  onFBShare,
  onDownlaod,
  onTwitterShare,
  duration,
  setDuration,
  downloadType,
  setDownloadType,
}) => {
  const [fileName, setFileName] = useState(null);
  const [error, setError] = useState("");

  const type = mode === "animate" ? "HighRes" : "PNG";
  useEffect(() => {
    setDownloadType(type);
  }, [type]);
  const { palette } = useTheme();
  const [buttons, setButtons] = useState([
    {
      name: "Download",
      selected: true,
    },
  ]);
  const socialButtons = [
    {
      name: "Twitter",
      icon: "https://storage.googleapis.com/3d-container/social-icons/twitter.png",
      onClick: () => onTwitterShare(),
    },
    {
      name: "Instagram",
      icon: "https://storage.googleapis.com/3d-container/social-icons/instagram.png",
      onClick: () => {},
    },
    {
      name: "Facebook",
      icon: "https://storage.googleapis.com/3d-container/social-icons/facebook.png",
      onClick: () => onFBShare(),
    },
    {
      name: "WhatsApp",
      icon: "https://storage.googleapis.com/3d-container/social-icons/whatsapp.png",
      onClick: () => {},
    },
    {
      name: "Email",
      icon: "https://storage.googleapis.com/3d-container/social-icons/mail.png",
      onClick: () => {},
    },
    {
      name: "Youtube",
      icon: "https://storage.googleapis.com/3d-container/social-icons/youtube.png",
      onClick: () => {},
    },
    {
      name: "Discord",
      icon: "https://storage.googleapis.com/3d-container/social-icons/discord.png",
      onClick: () => {},
    },
    {
      name: "Telegram",
      icon: "https://storage.googleapis.com/3d-container/social-icons/telegram.png",
      onClick: () => {},
    },
  ];

  const handleChange = (e) => {
    const value = e.target.value;

    if (Number(value) > 30) {
      setError("Duration cannot exceed 30 seconds");
    } else {
      setError("");
      setDuration(value);
    }
  };
  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="md"
        disableEnforceFocus
        slotProps={{
          backdrop: {
            style: {
              zIndex: 2,
              cursor: "pointer",
              backgroundColor: "#000",
              opacity: "0.7",
            },
          },
        }}
        PaperProps={{
          style: {
            backgroundColor: "#000",
            zIndex: 10,
            height: "40%",
            border: `1px solid ${palette?.button?.default}`,
            borderRadius: "20px",
          },
        }}
        onClose={() => setOpenShare(false)}
        open={openShare}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {buttons.map((button, i) => {
              return (
                <Box
                  key={i + "dialogButtons"}
                  sx={{
                    cursor: "pointer",
                    px: 4,
                    py: 1,
                    background: button.selected ? palette?.button?.default : "",
                  }}
                  onClick={() => {
                    const updated = buttons.map((btn) => {
                      if (btn.name === button.name) {
                        return {
                          ...btn,
                          selected: true,
                        };
                      }
                      return {
                        ...btn,
                        selected: false,
                      };
                    });
                    setButtons(updated);
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "700",
                      fontFamily: "var(--font-f_r)",
                      fontSize: {
                        xl: "1vw",
                        lg: "1vw",
                        md: "1vw",
                        xs: "4vw",
                        sm: "4vw",
                      },
                      textTransform: "capitalize",
                      textAlign: "center",
                      color: button.selected ? "black" : "#fff",
                    }}
                  >
                    {button.name}
                  </Typography>
                </Box>
              );
            })}
          </Box>
          <RxCross2
            style={{ marginRight: "20px", cursor: "pointer" }}
            onClick={() => setOpenShare(false)}
            color={"#F85B03"}
            size={25}
          />
        </Box>
        <Divider
          style={{
            width: "100%",
            height: "1px",
            border: "1px solid #0F0F0F",
          }}
        />
        {buttons.find((e) => e.selected)?.name === "Download" && (
          <>
            <Box p={3} display="flex" alignItems="end" width="100%">
              <Grid
                container
                spacing={2}
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid
                  item
                  justifyContent="flex-start"
                  alignItems="center"
                  lg={8}
                  xl={8}
                  xs={12}
                >
                  <Typography
                    sx={{
                      fontWeight: "600",
                      fontFamily: "var(--font-i_r)",
                      fontSize: "14px",
                      color: "#ffff",
                      alignSelf: "flex-start",
                      marginBottom: "5px",
                    }}
                  >
                    File Name
                  </Typography>
                  <TextField
                    id="name"
                    variant="outlined"
                    placeholder="Enter file name ..."
                    fullWidth
                    sx={{
                      color: "#fff",
                      fontFamily: "var(--font-i_r)",
                      "& .MuiInputBase-input": { color: "#fff" },
                      "& .MuiInputLabel-root": { color: "#fff" },
                      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: palette?.button?.default,
                        },
                    }}
                    value={fileName}
                    onChange={(e) => {
                      setFileName(e.target.value);
                    }}
                  />
                </Grid>
                <Grid
                  item
                  justifyContent="flex-start"
                  alignItems="center"
                  lg={4}
                  xl={4}
                  xs={12}
                >
                  <Typography
                    sx={{
                      fontWeight: "600",
                      fontFamily: "var(--font-i_r)",
                      fontSize: "14px",
                      color: "#ffff",
                      alignSelf: "flex-start",
                      marginBottom: "5px",
                    }}
                  >
                    File Format
                  </Typography>
                  {mode === "animate" ? (
                    <FormControl fullWidth>
                      <Select
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              backgroundColor: "#000", // Set dropdown background color to black
                              color: "#fff",
                            },
                          },
                        }}
                        value={downloadType}
                        onChange={(e) => setDownloadType(e.target.value)}
                        sx={{
                          width: "100%",
                          color: "#fff",
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: palette?.button?.default,
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: palette?.button?.default,
                          },
                          "& .MuiSvgIcon-root": {
                            color: "#fff",
                          },
                        }}
                        defaultValue={type}
                      >
                        <MenuItem value="LowRes">Low Res</MenuItem>
                        <MenuItem value="HighRes">High Res</MenuItem>
                        <MenuItem value="UltraHD">Ultra HD</MenuItem>
                      </Select>
                    </FormControl>
                  ) : (
                    <FormControl fullWidth>
                      <Select
                        value={downloadType}
                        onChange={(e) => setDownloadType(e.target.value)}
                        sx={{
                          width: "100%",
                          color: "#fff",
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: palette?.button?.default,
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: palette?.button?.default,
                          },
                          "& .MuiSvgIcon-root": {
                            color: "#fff",
                          },
                        }}
                        defaultValue={type}
                      >
                        <MenuItem value="PNG">PNG</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                </Grid>
              </Grid>
            </Box>
            <Box p={3} display="flex" alignItems="end" width="100%">
              <Grid
                container
                spacing={2}
                justifyContent="right"
                alignItems="center"
              >
                {mode === "animate" && (
                  <Grid
                    item
                    justifyContent="center"
                    alignItems="center"
                    lg={8}
                    xl={8}
                    xs={12}
                  >
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontFamily: "var(--font-i_r)",
                        fontSize: "14px",
                        color: "#ffff",
                        alignSelf: "flex-start",
                        marginBottom: "5px",
                      }}
                    >
                      Duration (max 30 seconds)
                    </Typography>
                    <TextField
                      variant="outlined"
                      placeholder="Enter file name ..."
                      fullWidth
                      sx={{
                        color: "#fff",
                        fontFamily: "var(--font-i_r)",
                        "& .MuiInputBase-input": { color: "#fff" },
                        "& .MuiInputLabel-root": { color: "#fff" },
                        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#fff",
                          },
                      }}
                      value={duration}
                      onChange={handleChange}
                      error={Boolean(error)}
                      helperText={error}
                    />
                  </Grid>
                )}
                <Grid
                  item
                  justifyContent="right"
                  alignItems="center"
                  lg={4}
                  xl={4}
                  xs={12}
                >
                  <HomeButton
                    title="Download"
                    onClick={() => {
                      onDownlaod(fileName, duration);
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </Dialog>
    </div>
  );
};

export const UploadDialog = ({ open, setOpen, onUpload }) => {
  const { palette } = useTheme();

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="md"
        disableEnforceFocus
        slotProps={{
          backdrop: {
            style: {
              zIndex: 2,
              cursor: "pointer",
              backgroundColor: "#000",
              opacity: "0.7",
            },
          },
        }}
        PaperProps={{
          style: {
            backgroundColor: "#000",
            zIndex: 10,
            height: "40%",
            border: `1px solid ${palette?.button?.default}`,
            borderRadius: "20px",
          },
        }}
        onClose={() => setOpen(false)}
        open={open}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                cursor: "pointer",
                px: 4,
                py: 1,
                background: palette?.button?.default,
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  fontFamily: "var(--font-f_r)",
                  fontSize: {
                    xl: "1vw",
                    lg: "1vw",
                    md: "1vw",
                    xs: "4vw",
                    sm: "4vw",
                  },
                  textTransform: "capitalize",
                  textAlign: "center",
                  color: "black",
                }}
              >
                Upload
              </Typography>
            </Box>
          </Box>
          <RxCross2
            style={{ marginRight: "20px", cursor: "pointer" }}
            onClick={() => setOpen(false)}
            color={"#F85B03"}
            size={25}
          />
        </Box>
        <Divider
          style={{
            width: "100%",
            height: "1px",
            border: "1px solid #0F0F0F",
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: "600",
              fontFamily: "var(--font-i_r)",
              fontSize: "0.8vw",
              marginBlock: "1vw",
              textAlign: "center",
              color: "#C4C4C4",
            }}
          >
            Click below box to upload audio file.
          </Typography>
          <Box
            sx={{
              display: "flex",
              width: "250px",
              borderRadius: "8px",
              border: `1px solid  ${palette?.button?.default}`,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {["MP3", "M4A", "Wav"].map((item, i) => {
              return (
                <Typography
                  key={i + item}
                  sx={{
                    fontWeight: "600",
                    paddingBlock: "10px",

                    fontFamily: "var(--font-f_r)",
                    fontSize: "1vw",
                    marginInline: ".9vw",
                    color: "#fff",
                  }}
                >
                  {item}
                </Typography>
              );
            })}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "92%",
              marginInline: "auto",
              borderRadius: "0.5vw",
              backgroundColor: "#242424",
              cursor: "pointer",
              alignItems: "center",
              justifyContent: "center",
              paddingBlock: "1vw",
              margin: "1vw",
            }}
            onClick={onUpload}
          >
            <img
              src="https://storage.googleapis.com/3d-container/Upload%20icon.png"
              alt="upload audio file"
            />

            <Typography
              sx={{
                fontWeight: "600",
                fontFamily: "var(--font-i_r)",
                fontSize: "0.7vw",
                marginBlock: "0.5vw",
                color: "#C4C4C4",
              }}
            >
              Select your audio file.
            </Typography>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
};
