import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import * as React from "react";
import { useNavigate } from "react-router-dom";

function DashboardLayout({ layoutOptions, title }) {
  const { palette, ...theme } = useTheme();
  const [step, setStep] = React.useState(0);
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
        width: "100vw",
      }}
    >
      <Box
        sx={{
          width: "96%",
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
          height: "100%",
          padding: 2,
          [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            mt: "100px",
            padding: 1,
            backdropFilter: "none",
          },
        }}
      >
        <Box
          sx={{
            width: "15%",
            justifyItems: "flex-start",
            height: "100%",
            fontWeight: "800",
            borderRadius: "15px",
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            backdropFilter: "blur(8px)",
            padding: "1vw",
            alignItems: "flex-start",
            [theme.breakpoints.down("sm")]: {
              width: "100%",
              height: "200px",
              padding: "10px",
              mb: 2,
              backdropFilter: "none",
            },
          }}
        >
          {layoutOptions?.map((option, i) => {
            return (
              <Box
                onClick={() => {
                  option?.navigate ? navigate(option?.navigate) : setStep(i);
                }}
                sx={{
                  cursor: "pointer",
                  padding: 2,
                  marginBlock: 2,
                  borderRadius: "10px",
                  border: step === i ? "1px solid #fff" : "none",
                  background: step === i ? "#EABE59" : "",
                  [theme.breakpoints.down("sm")]: {
                    width: "100%",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontFamily: "var(--font-f_r)",
                    fontSize: "1vw",
                    textTransform: "capitalize",
                    textAlign: "start",
                    color: step === i ? "#fff" : "#fff",
                    [theme.breakpoints.down("sm")]: {
                      fontSize: "14px",
                    },
                  }}
                >
                  {option.name}
                </Typography>
              </Box>
            );
          })}
        </Box>
        <Box
          sx={{
            width: "85%",
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            backdropFilter: "blur(8px)",
            marginLeft: "20px",
            height: "100%",
            borderRadius: "15px",
            [theme.breakpoints.down("sm")]: {
              width: "100%",
              marginLeft: "0px",
              backdropFilter: "none",
            },
          }}
        >
          {layoutOptions[step] && layoutOptions[step]?.children}
        </Box>
      </Box>
    </Box>
  );
}

export default DashboardLayout;
