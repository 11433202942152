import { useTheme } from "@emotion/react";
import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import {
  exitFullscreen,
  handleFullscreen,
  htmlToImageConvert,
  startRecording,
} from "../../../utils";
import ShareButton from "../../ShareButton";
import { SlSizeFullscreen } from "react-icons/sl";
import { MdOutlineQuestionMark } from "react-icons/md";
import "../../../components/Loader.css";
import { ShareDialog } from "../../ShareDialog/ShareDialog";
import { ConvertingVideoModal } from "../../ConvertingVideoModal/ConvertingVideoModal";
function ApeUnity({
  id = "",
  modelJson,
  mode = "3d",
  animation = "",
  environment = "",
}) {
  const [modelLoaded, setModelLoaded] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const theme = useTheme();
  const popupRef = React.useRef(null);
  const [openShare, setOpenShare] = useState(false);
  const [recording, setRecording] = useState(false);
  const canvasRef = useRef(null);

  const infoItems = [
    {
      topContent: null,
      img: "https://storage.googleapis.com/3d-container/svgs/3d/Rotate.svg",
      bottomContent: "Rotate",
    },
    {
      topContent: null,
      img: "https://storage.googleapis.com/3d-container/svgs/3d/Zoom.svg",
      bottomContent: "Zoom",
    },
  ];

  function handleCacheControl(url) {
    if (url.match(/\.data/) || url.match(/\.bundle/)) {
      return "must-revalidate";
    }
    if (url.match(/\.data/)) {
      return "immutable";
    }
    if (url.match(/\.mp4/) || url.match(/\.wav/)) {
      return "immutable";
    }
    return "no-store";
  }

  const unity = useUnityContext({
    loaderUrl:
      "https://857f8b67-8251-447b-b035-befadff065c3.client-api.unity3dusercontent.com/client_api/v1/environments/production/buckets/ea256bbc-8e1c-433e-921c-028ff4b762fb/release_by_badge/latest/entry_by_path/content/?path=ATS.loader.js",
    frameworkUrl:
      "https://857f8b67-8251-447b-b035-befadff065c3.client-api.unity3dusercontent.com/client_api/v1/environments/production/buckets/ea256bbc-8e1c-433e-921c-028ff4b762fb/release_by_badge/latest/entry_by_path/content/?path=ATS.framework.js.unityweb",
    dataUrl:
      "https://857f8b67-8251-447b-b035-befadff065c3.client-api.unity3dusercontent.com/client_api/v1/environments/production/buckets/ea256bbc-8e1c-433e-921c-028ff4b762fb/release_by_badge/latest/entry_by_path/content/?path=ATS.data.unityweb",
    codeUrl:
      "https://857f8b67-8251-447b-b035-befadff065c3.client-api.unity3dusercontent.com/client_api/v1/environments/production/buckets/ea256bbc-8e1c-433e-921c-028ff4b762fb/release_by_badge/latest/entry_by_path/content/?path=ATS.wasm.unityweb",
    streamingAssetsUrl:
      "https://857f8b67-8251-447b-b035-befadff065c3.client-api.unity3dusercontent.com/client_api/v1/environments/production/buckets/ea256bbc-8e1c-433e-921c-028ff4b762fb/release_by_badge/latest/entry_by_path/content/?path=StreamingAssets",
    cacheControl: handleCacheControl,
    webglContextAttributes: {
      preserveDrawingBuffer: true,
    },
  });

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setShowInfo(false);
    }
  };

  useEffect(() => {
    if (showInfo) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showInfo]);

  useEffect(() => {
    unity?.addEventListener("NFTLoaded", (e) => {
      if (e === 1) {
        setModelLoaded(true);
      }
    });
    return () => {
      unity?.removeEventListener("NFTLoaded", () => {});
    };
  }, [unity?.addEventListener, unity?.removeEventListener, window]);

  useEffect(() => {
    if (unity?.isLoaded && modelJson) {
      unity?.sendMessage(
        "Manager",
        "ReceiveJsonValues",
        JSON.stringify({
          ...modelJson,
        })
      );
    }
    // eslint-disable-next-line
  }, [unity?.isLoaded, modelJson]);

  useEffect(() => {
    if (unity?.isLoaded && animation && modelLoaded) {
      unity?.sendMessage("Manager", "ChangeAnimations", animation);
    }
  }, [unity?.isLoaded, animation, modelLoaded]);

  useEffect(() => {
    if (unity?.isLoaded && environment && modelLoaded) {
      unity?.sendMessage("Manager", "ChangeBackGrounds", environment);
    }
    // eslint-disable-next-line
  }, [unity?.isLoaded, environment, modelLoaded]);

  const handleShare = () => {
    setOpenShare(!openShare);
  };

  const [duration, setDuration] = useState(6);
  const [converting, setConverting] = useState(false);
  const [downloadType, setDownloadType] = useState("HighRes");
  const [fullScreen, setFullScreen] = useState(false);
  useEffect(() => {
    const handleFullscreenChange = () => {
      setFullScreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
    document.addEventListener("mozfullscreenchange", handleFullscreenChange);
    document.addEventListener("MSFullscreenChange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "MSFullscreenChange",
        handleFullscreenChange
      );
    };
  }, []);
  const onDownlaod = async (fileName, dur) => {
    setDuration(dur);
    if (mode === "animate") {
      window.scrollTo(0, 0);
      startRecording(
        "canvas",
        fileName ? fileName : id,
        (e) => setRecording(e),
        "",
        () => {},
        dur,
        (e) => {
          setConverting(e);
        },
        downloadType
      );
    } else {
      setOpenShare(false);
      handleFullscreen(canvasRef, setFullScreen);
      setTimeout(() => {
        htmlToImageConvert(fileName, canvasRef, setFullScreen);
      }, 1000);
    }
    setOpenShare(false);
  };
  return (
    <Box
      width={"100%"}
      sx={{
        display: "flex",
        margin: "auto",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
          flexDirection: "column",
        },
      }}
    >
      {!unity?.isLoaded && (
        <Box
          sx={{
            width: "100vw",
            position: "absolute",
            display: unity?.isLoaded ? "none" : "flex",
            height: "100vh",
            backdropFilter: "blur(10px)",
            background: "rgba(0,0,0,0.5)",
            zIndex: 100000,
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {converting && (
        <ConvertingVideoModal
          open={converting}
          duration={duration}
          downloadType={downloadType}
        />
      )}
      <Box
        ref={canvasRef}
        onDoubleClick={() => {
          fullScreen
            ? exitFullscreen(canvasRef, setFullScreen)
            : handleFullscreen(canvasRef, setFullScreen);
        }}
        sx={{
          width: fullScreen ? "100vw" : "67%",
          border: "",
          height: fullScreen ? "100vh" : "82vh",
          padding: "14px",
          borderRadius: "0.8vw",
          [theme.breakpoints.down("sm")]: {
            width: "100%",
            height: "70vh",
            borderRadius: "10px",
          },
        }}
        id="canvas"
      >
        <Unity
          style={{
            width: "100%",
            height: "100%",
          }}
          id="unityCanvas"
          unityProvider={unity?.unityProvider}
          devicePixelRatio={3}
        />
      </Box>
      {showInfo && (
        <Box
          ref={popupRef}
          sx={{
            position: "absolute",
            display: "flex",
            top: "10%",
            right: "35%",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "12px",
            background: "#212121",
            p: 2,
            [theme.breakpoints.down("sm")]: {
              top: "6%",
              right: "7%",
            },
          }}
        >
          {infoItems.map((info) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mx: 2,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontFamily: "var(--font-i_r)",
                      fontSize: "0.6vw",
                      color: "#fff",
                      [theme.breakpoints.down("sm")]: {
                        fontSize: "10px",
                      },
                    }}
                  >
                    {info.topContent}
                  </Typography>
                  <img src={info.img} />
                </Box>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontFamily: "var(--font-i_r)",
                    fontSize: "0.6vw",
                    color: "#fff",
                    [theme.breakpoints.down("sm")]: {
                      fontSize: "10px",
                    },
                  }}
                >
                  {info.bottomContent}
                </Typography>
              </Box>
            );
          })}
        </Box>
      )}
      <Box
        sx={{
          position: "absolute",
          top: "5%",
          right: "35%",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          paddingBottom: "1.5vh",
          [theme.breakpoints.down("sm")]: {
            top: "3%",
            right: "7%",
          },
        }}
      >
        <IconContainer
          theme={theme}
          icon={SlSizeFullscreen}
          onClick={() => handleFullscreen(canvasRef)}
        />
        <IconContainer
          theme={theme}
          icon={MdOutlineQuestionMark}
          onClick={() => !showInfo && setShowInfo(true)}
        />
      </Box>

      {recording && (
        <Box
          sx={{
            display: "flex",
            position: "absolute",
            top: 36,
            width: "25%",
            p: 1,
            ml: 3,
            mb: 1,
            background: "#222423",
            borderRadius: "0.5vw",
            alignItems: "center",
            justifyContent: "flex-start",
            [theme.breakpoints.down("sm")]: {
              width: "55%",
              top: 20,
              ml: 0,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "60px",
              p: "6px",
              background: "#F90000",
              borderRadius: "0.3vw",
              mr: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="circle"></div>
            <Typography
              sx={{
                fontWeight: "400",
                fontFamily: "var(--font-i_r)",
                fontSize: "0.45vw",
                color: "#fff",
                [theme.breakpoints.down("sm")]: {
                  fontSize: "10px",
                },
              }}
            >
              REC
            </Typography>
          </Box>
          <Typography
            sx={{
              fontWeight: "400",
              fontFamily: "var(--font-i_r)",
              fontSize: "0.45vw",
              width: "90%",
              opacity: "70%",
              color: "#fff",
              [theme.breakpoints.down("sm")]: {
                fontSize: "8px",
              },
            }}
          >
            Recording in progress, it will be end within {duration} seconds.
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          position: "absolute",
          bottom: 50,
          zIndex: 10000,
          width: "70%",
          justifyContent: "center",
          [theme.breakpoints.down("sm")]: {
            width: "100%",
            bottom: 0,
            justifyContent: "center",
          },
        }}
      >
        {/* <ProjectButton name="Share" onClick={handleShare} /> */}
        <ProjectButton name="Download" onClick={handleShare} />
      </Box>
      <ShareDialog
        mode={mode}
        duration={duration}
        setDuration={setDuration}
        setDownloadType={setDownloadType}
        downloadType={downloadType}
        openShare={openShare}
        setOpenShare={setOpenShare}
        onTwitterShare={() => {}}
        onFBShare={() => {}}
        onDownlaod={(e, a) => onDownlaod(e, a)}
      />
    </Box>
  );
}

export default ApeUnity;

const ProjectButton = ({ name, onClick }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        cursor: "pointer",
        marginInline: "0.5vw",
        zIndex: 999,
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          width: "12vw",
          border: "2px solid #fff",
          borderRadius: "10px",
          height: "65px",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          background: "rgba(0, 0, 0, 0.1)",
          backdropFilter: "blur(8px)",
          color: "white",
          "&:hover": {
            background: "#EABE59",
          },
          [theme.breakpoints.down("sm")]: {
            width: "90%",
            marginBottom: "10px",
            maxWidth: "150px",
            minWidth: "120px",
            height: "35px",
            borderRadius: "8px",
          },
        }}
      >
        <Typography
          sx={{
            fontWeight: "700",
            fontFamily: "var(--font-f_r)",
            fontSize: "1vw",
            textTransform: "capitalize",
            textAlign: "center",
            [theme.breakpoints.down("lg")]: {
              fontSize: "16px",
            },
            [theme.breakpoints.down("sm")]: {
              fontSize: "12px",
            },
          }}
        >
          {name}
        </Typography>
      </Box>
    </Box>
  );
};
const IconContainer = ({ onClick, icon: Icon, theme }) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        height: "2vw",
        width: "2vw",
        marginLeft: "1vw",
        border: "1px solid #EABE59",
        p: 0.5,
        borderRadius: "4px",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        justifyContent: "center",
        [theme.breakpoints.down("sm")]: {
          height: "26px",
          width: "26px",
        },
      }}
    >
      <Icon onClick={onClick} size={18} color="#EABE59" />
    </Box>
  );
};
