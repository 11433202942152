import { Typography, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import { useNavigate } from "react-router-dom";

function Menus({}) {
  const { palette, ...theme } = useTheme();
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        border: "1px solid #94E161",
        width: "30vw",
        display: "flex",
        flexWrap: "wrap",
        // marginTop: "240px",
        height: "100%",
        alignItems: "center",
        backdropFilter: "blur(10px)",
        justifyContent: "center",
        minWidth: "450px",
        padding: 1,
        borderRadius: "15px",
      }}
    >
      {DASHBOARD_OPTIONS?.map((option) => {
        return (
          <Box
            onClick={() => navigate(option.route)}
            key={option.id}
            sx={{
              cursor: "pointer",
              border: "1px solid #9c9c9c",
              paddingBlock: 3,
              margin: 1,
              borderRadius: "15px",
              display: "flex",
              flexDirection: "column",
              width: "7vw",
              justifyContent: "center",
              height: "15vh",
              alignItems: "center",
              backgroundColor: "#000",
              transition: "all .4s ease-in-out",
              "&:hover": {
                ".menus-text": {
                  color: "#94E161",
                },
                border: "1px solid #94E161",
              },
            }}
          >
            <img height="70vh" src={option.icon} />
            <Typography
              className="menus-text"
              sx={{
                fontWeight: "700",
                textTransform: "capitalize",
                fontFamily: "var(--font-f_r)",
                fontSize: "0.8vw",
                marginTop: "0.9vw",
                color: palette.grey[200],
                opacity: 0.7,
                [theme.breakpoints.down("sm")]: {
                  fontSize: "14px",
                },
              }}
            >
              {option.name}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
}

export default Menus;

const DASHBOARD_OPTIONS = [];
