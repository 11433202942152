import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { CircularProgress, Typography } from "@mui/material";
import React from "react";
const Root = styled("div")(({ theme, full }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  height: "55px",
  marginTop: "26px",
  // paddingInline: "10px",
  // width: "8vw",
  [`@media (max-width:${theme.breakpoints.values["sm"]}px)`]: {
    width: "100px",
    height: "40px",
  },
  cursor: "pointer",
  borderRadius: "10px",
  border: `1px solid ${theme.palette.primary[100]}`,
  "& .MuiTypography-root": {
    fontWeight: "700",
    textTransform: "capitalize",
    fontFamily: "var(--font-f_r)",
    fontSize: "1vw",
    paddingLeft: "20px",
    paddingRight: "10px",
    color: theme.palette.grey[200],
    [`@media (max-width:${theme.breakpoints.values["sm"]}px)`]: {
      fontSize: "12px",
    },
  },
}));

const HomeButton = ({
  title,
  onClick,
  className,

  icon,
  type,
  size,
  full,
  outline,
  loading,
  ...rest
}) => {
  const theme = useTheme();
  return (
    <Root onClick={onClick} full={full} className={className}>
      {icon}
      {loading ? (
        <CircularProgress size={24} />
      ) : (
        <Typography sx={{ marginRight: "10px" }}>{title}</Typography>
      )}
    </Root>
  );
};

export default HomeButton;
