import React from "react";
import GlobalStyles from "@mui/material/GlobalStyles";
import { useTheme } from "@mui/material";
import { minHeight, padding } from "@mui/system";

export const GlobalTheme = ({ theme }) => {
  const { palette } = useTheme();
  return (
    <GlobalStyles
      styles={{
        html: {
          "-webkit-font-smoothing": "antialiased",
          "-moz-osx-font-smoothing": "grayscale",
        },
        "::selection": {
          backgroundColor: palette.primary[500],
          color: palette.grey[200],
        },
        "::-webkitScrollbar": { width: 0 },
        "html,body": {
          overflowX: "hidden !important",
          padding: " 0 !important",
        },
        "*, *::before, *::after": {
          margin: 0,
          padding: 0,
          boxSizing: "border-box",
        },
        "h1,h2,h3,h4,h5,h6,div,span,p": { padding: 0, margin: 0 },
        a: {
          textDecoration: "none",
          outline: "none",
          "&:focus,&:hover, &:active, &:visited, &:hover": {
            textDecoration: "none",
            outline: "none",
          },
        },
        "& .MuiMenu-paper .MuiMenu-list": {
          padding: "0",
          "& li": {
            fontFamily: "var(--font-p_r)",
            padding: "9px",
            "&.Mui-selected": {
              background: palette.primary[500],
              color: palette.grey[200],
            },
            "&:hover": {
              background: palette.primary[500],
              color: palette.grey[200],
            },
          },
        },
        "& .MuiCheckbox-root.Mui-checked": {
          "& svg": {
            color: palette.primary[500],
          },
        },
        "& .MuiTypography-inputLabel": {
          fontSize: "30xp",
          [theme.breakpoints.down("sm")]: {
            fontSize: "14px", // Example of responsive font size
          },
          [theme.breakpoints.down("md")]: {
            fontSize: "20px", // Example of responsive font size
          },
          [theme.breakpoints.down("lg")]: {
            fontSize: "24px", // Example of responsive font size
          },
        },
        button: { border: "none", outline: "none" },
        ul: { padding: 0, listStyle: "none" },
        "& .MuiMenu-list": {
          "& li": {
            textTransform: "capitalize",
          },
        },
        "& [dir|='rtl']": {
          "& .form-group": {
            "& label": {
              transform: "translateX(24%) scale(0.75)",
              textAlign: "right",
            },
            "& .MuiFormControl-root .MuiButtonBase-root": {
              right: "inherit",
              left: "10px",
            },
          },
          "& .phone-input": {
            "& .MuiInputLabel-root": {
              transform: "translateX(24%) scale(0.75)",
              textAlign: "right",
            },
            "& .form-control ": {
              padding: "18.5px 60px 18.5px 14px",
            },
            "& .selected-flag": {
              padding: "0  11px 0 0",
              "& .arrow": {
                left: "inherit",
                right: "29px",
              },
            },
          },
        },
        "& .form-group": {
          width: "100%",
          marginBottom: "20px",
          "& textarea": {
            textTransform: "capitalize",
            fontWeight: "400",
            fontSize: "16px",
            fontFamily: "var(--font-p_r)",
            height: "140px",
            resize: "none",
            outline: "none",
            cursor: "pointer",
            padding: "10px",
            background: palette.grey[400],
            border: `1px solid ${palette.secondary[100]}`,
            borderRadius: "10px",
          },
          "& .date": {
            border: "1px solid #cccccc",
            borderRadius: "10px",
            backgroundColor: "#F9F9F9",
            width: "100%",
            textTransform: "capitalize",
            fontWeight: "400",
            fontSize: "14px",
            fontFamily: "var(--font-p_r)",
            color: palette.secondary[700],
            padding: "12px",
            cursor: "pointer",
            [theme.breakpoints.up("sm")]: {
              padding: "14px",
              fontSize: "16px",
            },
          },
          "&:last-child": {
            marginBottom: "0",
          },
          "& .MuiFormLabel-root": {
            fontSize: " 18px",
            fontWeight: "500",
            color: palette.secondary[500],
            textTransform: "capitalize",
            fontFamily: "var(--font-p_m)",
            width: "320px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            [theme.breakpoints.up("sm")]: {
              fontSize: " 20px",
              width: "100%",
            },
          },
          "& .MuiFormControl-root": {
            width: "100%",
            position: "relative",
            "& input": {
              fontWeight: "400",
              fontSize: "14px",
              fontFamily: "var(--font-p_r)",
              color: palette.secondary[700],
              padding: "12px",
              cursor: "pointer",
              [theme.breakpoints.up("sm")]: {
                padding: "14px",
                fontSize: "16px",
              },
            },

            "& .MuiButtonBase-root": {
              position: "absolute",
              right: "10px",
              transition: "all 0.3s",
              "&:hover": {
                color: palette.grey[200],
                background: "#5e5737",
              },
            },
            " .MuiInputBase-root": {
              backgroundColor: palette.grey[400],
              borderRadius: "10px",
              padding: "0",
            },
            "& .error": {
              margin: "10px 0 0 0",
              fontSize: "12px",
              fontFamily: "var(--font-p_m)",
              color: "red",
            },
            "& .MuiSelect-select": {
              color: palette.secondary[700],
              [theme.breakpoints.up("sm")]: {
                padding: "14px",
                "& span": {
                  fontSize: "16px",
                },
              },
              "& span": {
                textTransform: "capitalize",
                fontWeight: "400",
                fontSize: "14px",
                fontFamily: "var(--font-p_r)",
                color: palette.grey[600],
              },
              paddingRight: "0 !important",
              padding: "12px",
              textTransform: "capitalize",
            },
          },
        },
        "& .buton-hover": {
          "& .MuiFormControl-root": {
            width: "100%",
            "& .react-tel-input ": {
              "& .form-control": {
                width: "100%",
                backgroundColor: "#5e5737",
                borderRadius: "10px",
                border: "1px solid #cccccc",
                "&:focus": {
                  outline: "none",
                  boxShadow: "none",
                  borderColor: theme.palette.secondary[500],
                },
              },
            },
          },
        },
        "& .phone-input": {
          "& .MuiFormControl-root": {
            width: "100%",
            "& .react-tel-input ": {
              "& .form-control": {
                width: "100%",
                backgroundColor: theme.palette.grey[400],
                borderRadius: "10px",
                border: "1px solid #cccccc",
                "&:focus": {
                  outline: "none",
                  boxShadow: "none",
                  borderColor: theme.palette.secondary[500],
                },
              },
            },
          },
        },
        "& .dashboard-footer": {
          margin: "30px 16px 16px",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          flexDirection: "column-reverse",
          rowGap: "10px",
          [theme.breakpoints.up("sm")]: {
            rowGap: "0",
            flexDirection: "row",
          },
          "& .footer-item": {
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            cursor: "pointer",
            gap: "15px",
            "& .MuiTypography-body1": {
              fontSize: "16px",
              fontFamily: "var(--font-p_m)",
              fontWeight: "500",
            },
          },
        },
        "& .MuiFormControlLabel-root": {
          "& .MuiTypography-body1": {
            textTransform: "capitalize",
            fontWeight: "400",
            fontSize: "16px",
            fontFamily: "var(--font-p_m)",
            color: palette.secondary[500],
          },
        },
        "#long-menu": {
          "& .MuiPaper-root": {
            marginTop: "15px",
          },
        },
        "& .MuiTabs-root": {
          minHeight: "72px"

        }
      }}
    />
  );
};
