import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { themeSettings } from "./theme";
import { GlobalTheme } from "./theme/GlobalTheme";
import { SnackbarProvider } from "notistack";
import Dashboard3d from "./components/Menus/dashboard3d";
import StillPose from "./components/Menus/dashboard3d/still-pose";
import File from "./components/Menus/files";
import { LoaderProvider } from "./context/loaderContext";
import { useDarkMode } from "./context/themeContext";
import { Home, NftModelDetails } from "./pages";
import NftDetails from "./pages/nftDetails";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ThirdwebProvider } from "thirdweb/react";
import Minting from "./pages/minting";
import { Header } from "./components";
import { AuthProvider } from "./context/AuthContext";
import PrivateRoute from "./components/PrivateRoute";

const queryClient = new QueryClient();

function App() {
  const { darkMode } = useDarkMode();
  const [themeModeSettings, setThemeModeSettings] = useState({
    grey: {
      100: "#f6f6f6",
      200: "#ffffff",
      300: "#070c3c00",
      400: "#F9F9F9",
      500: "#1e1e1e80",
      600: "#a7a7a7",
      700: "#F3F3F3",
      800: "#48494e",
      900: "#242427",
    },
    primary: {
      // light blue
      100: "#EABE59",
      200: "#EAD30C6B",
    },
    secondary: {
      // Black
      100: "#DECE82",
      200: "#1e1e1eab",
      300: "#1e1e1e99",
      400: "#1e1e1e8a",
      500: "#1e1e1ecc",
      600: "#1e1e1e82",
      700: "#1E1E1E",
      800: "#11154B",
      900: "#000000",
    },
    text: {
      default: "#2b2b2b",
      primary: "#DECE82",
    },
    dark: {
      text: "#2b2b2b",
      bg: "#fff",
      disableLabel: "#e8e6e6",
      connect: "#fff",
    },
  });

  useEffect(() => {
    if (darkMode) {
      setThemeModeSettings({
        ...themeModeSettings,
        dark: {
          text: "#dbd9d9",
          bg: "#292929",
          disableLabel: "#9c9898",
          connect: "#fff",
        },
      });
    } else {
      setThemeModeSettings({
        ...themeModeSettings,
        dark: {
          text: "#2b2b2b",
          bg: "#fff",
          disableLabel: "#7c7676",
          connect: "#000",
        },
      });
    }
  }, [darkMode]);

  return (
    <ThirdwebProvider activeChain="ethereum">
      <LoaderProvider>
        <ThemeProvider theme={themeSettings(themeModeSettings)}>
          <GlobalTheme theme={themeSettings(themeModeSettings)} />
          <SnackbarProvider
            autoHideDuration={3000}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <CssBaseline />
            <QueryClientProvider client={queryClient}>
              <BrowserRouter>
                <AuthProvider>
                  <Routes>
                    <Route
                      path="/"
                      element={
                        <Header>
                          <Home />
                        </Header>
                      }
                    />
                    <Route
                      path="/:id"
                      element={
                        <Header>
                          <PrivateRoute>
                            <NftDetails />
                          </PrivateRoute>
                        </Header>
                      }
                    />
                    <Route
                      path="/:id/:nftId"
                      element={
                        <Header>
                          <PrivateRoute>
                            <NftModelDetails />
                          </PrivateRoute>
                        </Header>
                      }
                    />
                    <Route
                      path="/:id/animation"
                      element={
                        <Header>
                          <PrivateRoute>
                            <Dashboard3d />
                          </PrivateRoute>
                        </Header>
                      }
                    />
                    <Route
                      path="/:id/stillPose"
                      element={
                        <Header>
                          <PrivateRoute>
                            <StillPose />
                          </PrivateRoute>
                        </Header>
                      }
                    />
                    <Route
                      path="/:id/file"
                      element={
                        <Header>
                          <PrivateRoute>
                            <File />
                          </PrivateRoute>
                        </Header>
                      }
                    />
                  </Routes>
                </AuthProvider>
              </BrowserRouter>
            </QueryClientProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </LoaderProvider>
    </ThirdwebProvider>
  );
}

export default App;
