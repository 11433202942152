import { useTheme } from "@emotion/react";
import HomeIcon from "@mui/icons-material/Home";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import {
  AppBar,
  Box,
  Grid,
  IconButton,
  MenuItem,
  MenuList,
  styled,
  Switch,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Modal from "@mui/material/Modal";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDarkMode } from "../context/themeContext";
import { homeBanner, homeBannerDark } from "../staticData/images";
import BackButton from "./BackButton";
import {
  ConnectEmbed,
  useActiveWalletConnectionStatus,
  useActiveWallet,
  useDisconnect,
  useActiveAccount,
} from "thirdweb/react";
import { createThirdwebClient } from "thirdweb";
import { createWallet, walletConnect } from "thirdweb/wallets";
import { sepolia } from "thirdweb/chains";
import { useAuth } from "../context/AuthContext";
const isDarkMode = localStorage.getItem("darkMode") === "true" ? true : false;
function Header({ setParallax, onPageChange, setIsDarkMode, children }) {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const { darkMode, setDarkMode } = useDarkMode();
  const [isMd, setIsMd] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isConnectDialogActive, setIsConnectDialogActive] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  let wallet = useActiveWallet();
  let account = useActiveAccount();
  const connectionStatus = useActiveWalletConnectionStatus();
  const { disconnect } = useDisconnect();
  const { login, logout, user, ownNft, nfts, verifyToken } = useAuth();
  const { id } = useParams();
  const client = createThirdwebClient({
    clientId: process.env.REACT_APP_THIRDWEB_CLIENTID,
  });
  useEffect(() => {
    verifyToken(id);
  }, [id]);
  const wallets = [
    createWallet("io.metamask"),
    createWallet("com.coinbase.wallet"),
    walletConnect(),
  ];
  const handleWalletDisconnect = () => {
    if (
      connectionStatus === "connected" ||
      connectionStatus === "disconnected"
    ) {
      logout();
      if (wallet) disconnect(wallet);
      navigate("/");
    }
  };

  const handleToggleConnectDialog = () => {
    setIsConnectDialogActive(!isConnectDialogActive);
  };
  const DarkModeSwitch = styled(Switch)(({ theme }) => ({
    selectedTab: {
      color: "#1976d2", // Set the color for the active tab
    },
    width: 62,
    height: 34,
    padding: 7,
    "& .MuiSwitch-switchBase": {
      margin: 1,
      padding: 0,
      transform: "translateX(6px)",
      "&.Mui-checked": {
        color: "#fff",
        transform: "translateX(22px)",
        "& .MuiSwitch-thumb:before": {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
            "#fff"
          )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
        },
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
      width: 32,
      height: 32,
      "&::before": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
      },
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      borderRadius: 20 / 2,
    },
  }));

  const handleNavbarToggle = (event) => {
    setIsMenuOpen(!isMenuOpen);
    setAnchorEl(event.currentTarget);
  };

  const handleNavbarClose = () => {
    setIsMenuOpen(false);
    setAnchorEl(null);
  };

  const handleListKeyDown = (event) => {
    if (event.key === "Tab" || event.key === "Escape") {
      event.preventDefault();
      handleNavbarClose();
    }
  };

  const handlePageChange = (newValue) => {
    setValue(newValue);
    onPageChange(newValue);
    setParallax(newValue * -200);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleRouteHome = () => {
    navigate("/");
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMd(window.innerWidth > 900);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getSign = async (address) => {
    const message = "Sign in with ATS";
    await account?.signMessage({ message });
    await login(address, sepolia.id.toString());
    await ownNft(null, id);
  };
  useEffect(() => {
    if (connectionStatus === "connected") {
      setIsConnectDialogActive(false);
      getSign(account?.address);
    }
  }, [connectionStatus]);

  const isHome = window.location.pathname === "/";
  const { palette, ...theme } = useTheme();

  const Logo = () => (
    <Grid
      item
      xs={2}
      sx={{
        display: "flex",
        justifyContent: "center",
        my: "auto",
        height: "100%",
        cursor: "pointer",
      }}
      onClick={handleRouteHome} // Add onClick event handler
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            mt: isMd ? 0 : 4,
            fontSize: isMd ? 28 : 32,
            fontFamily: "var(--font-i_p)",
            color: "#ffffff",
          }}
        >
          greAt
        </Typography>
        <Typography
          variant="h6"
          sx={{
            fontSize: isMd ? 28 : 32,
            fontFamily: "var(--font-i_p)",
            color: "#ffffff",
            marginTop: "-14px",
          }}
        >
          APes
        </Typography>
      </Box>
    </Grid>
  );
  const Connect = ({}) => {
    return (
      <Box sx={{ my: 1, mx: 1.5, display: "flex" }}>
        {user && user.walletAddress ? (
          <Button
            startIcon={<LogoutIcon />}
            size="small"
            onClick={() => {
              handleWalletDisconnect();
            }}
            variant="outlined"
            sx={{
              lineHeight: 0.9,
              whiteSpace: "normal",
              wordBreak: "break-word",
              width: "160px",
              borderRadius: 3,
              fontWeight: 700,
              textTransform: "none",
              borderColor: palette.dark.text,
              color: palette.dark.text,
            }}
          >
            {user && user.walletAddress
              ? user.walletAddress.slice(0, 6) +
                "..." +
                user.walletAddress.slice(-4)
              : "Disconnect"}
          </Button>
        ) : (
          <Button
            startIcon={<LoginIcon />}
            size="small"
            onClick={() => {
              handleToggleConnectDialog();
            }}
            variant="outlined"
            sx={{
              lineHeight: 0.9,
              whiteSpace: "normal",
              wordBreak: "break-word",
              width: "160px",
              borderRadius: 3,
              fontWeight: 700,
              textTransform: "none",
              borderColor: palette.dark.text,
              color: palette.dark.text,
            }}
          >
            Connect Wallet
          </Button>
        )}
      </Box>
    );
  };

  const renderLabel = (text) => (
    <Box
      sx={{
        py: 1.5,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography>{text}</Typography>
    </Box>
  );

  return (
    <Box
      sx={{
        zIndex: 1000,
        height: !isMd ? "93vh" : "100vh",
      }}
    >
      <Box
        sx={{
          zIndex: 1000,
          height: "100vh",
          overflowY: "scroll",
          backgroundColor: "rgba(0, 0, 0, 0.2)",
          backdropFilter: "blur(10px)",
          background: darkMode
            ? `url('${homeBannerDark}')`
            : `url('${homeBanner}')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          // [theme.breakpoints.down("sm")]: {
          //   height: "100%",
          // },
        }}
      >
        <Grid container sx={{ display: "flex", justifyContent: "center" }}>
          <Logo />
          {!isHome && (
            <Box
              sx={{
                position: "absolute",
                top: "80px",
                left: "80px",
                [theme.breakpoints.down("sm")]: {
                  top: "140px",
                  left: "40px",
                },
              }}
            >
              <BackButton onClick={() => {}} />
            </Box>
          )}
          <Box sx={{ display: !isMd ? "none" : "inline" }}>
            <AppBar
              sx={{
                position: "static",
                borderRadius: 10,
                backgroundColor: palette.dark.bg,
                my: 3,
                width: "100%",
              }}
            >
              <Toolbar>
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      variant={isMd ? "scrollable" : "standard"}
                      scrollButtons={isMd ? "off" : "auto"}
                      style={{
                        height: "64px",
                      }}
                      TabIndicatorProps={{
                        style: {
                          transition:
                            "left 1s ease-in-out, width 1s ease-in-out",
                          backgroundColor: "#1976d2",
                          color: palette.dark.text,
                          textDecorationColor: "#1976d2",
                          textTransform: "capitalize",
                        },
                      }}
                    >
                      <Tab
                        style={{
                          color: value === 0 ? "#1976d2" : palette.dark.text, // Set active color if selected
                          fontFamily: "var(--font-m_r)",
                          textTransform: "capitalize",
                        }}
                        onClick={() => navigate("/")}
                        label={renderLabel("ApeStudio")}
                        selected={value === 0}
                      />
                      <Tab
                        style={{
                          color: value === 1 ? "#1976d2" : palette.dark.text, // Set active color if selected
                          fontFamily: "var(--font-m_r)",
                          textTransform: "capitalize",
                        }}
                        onClick={() =>
                          window.open("https://grapes.gg/", "_self")
                        }
                        label={"Home"}
                        selected={value === 1} // Check if this tab is selected
                      />
                    </Tabs>
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <DarkModeSwitch
                      sx={{ mx: 1.5, my: "auto" }}
                      defaultChecked={darkMode}
                      onChange={() => {
                        localStorage.setItem(
                          "darkMode",
                          darkMode ? "false" : "true"
                        );
                        setDarkMode(!darkMode);
                      }}
                    />
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <Connect />
                  </Box>
                </Box>
              </Toolbar>
            </AppBar>
          </Box>
          {/* Mobile Dropdown Menu */}
          <SwipeableDrawer
            variant="temporary"
            ModalProps={{
              keepMounted: true,
            }}
            onOpen={() => setIsMenuOpen(true)}
            open={isMenuOpen}
            onClose={handleNavbarClose}
            anchor="bottom"
            anchorEl={anchorEl}
            PaperProps={{
              elevation: 0,
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            sx={{
              backdropFilter: "blur(5px)",
            }}
          >
            <MenuList
              autoFocusItem={isMenuOpen}
              id="composition-menu"
              onKeyDown={handleListKeyDown}
              sx={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: palette.dark.bg,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  backgroundColor: "rgba(0, 0, 0, 0.2)",
                  width: 100,
                  height: 6,
                  borderRadius: 5,
                  margin: "auto",
                  mt: 1,
                }}
              ></Box>
              <MenuItem
                onClick={() => {
                  navigate("/");
                  handleNavbarClose();
                }}
                sx={{ color: palette.dark.text }}
              >
                ApeStudio
              </MenuItem>
              <MenuItem
                onClick={() => {
                  window.open("https://grapes.gg/", "_self");
                }}
                sx={{ color: palette.dark.text }}
              >
                Home
              </MenuItem>
              <MenuItem>
                <Connect mobilewidth={6} />
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={() => {
                  localStorage.setItem("darkMode", darkMode ? "false" : "true");
                  setDarkMode(!darkMode);
                }}
              >
                <DarkModeSwitch
                  sx={{ mx: 0, my: "auto" }}
                  defaultChecked={darkMode}
                  onChange={() => setDarkMode(!darkMode)}
                />
              </MenuItem>
            </MenuList>
          </SwipeableDrawer>
        </Grid>
        <Box>{children}</Box>
      </Box>
      <Modal
        open={isConnectDialogActive}
        onClose={() => setIsConnectDialogActive(false)}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backdropFilter: "blur(5px)",
        }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <span>
          <ConnectEmbed modalSize="compact" client={client} wallets={wallets} />
        </span>
      </Modal>
      {!isMd && (
        <AppBar
          position="sticky"
          sx={{
            top: "auto",
            backgroundColor: palette.dark.bg,
            bottom: 0,
            width: "100%",
            padding: 0,
            right: 0,
            py: 1,
            px: 1,
            paddingBottom: "env(safe-area-inset-bottom)",
          }}
        >
          <Toolbar
            sx={{
              display: "flex",
              backgroundColor: palette.dark.bg,
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ width: "40px" }}></Box>
            <IconButton
              sx={{
                width: "40px",
                boxShadow: 2,
                borderRadius: 5,
                backgroundColor: "inherit",
              }}
              onClick={() => handleRouteHome()}
            >
              <HomeIcon sx={{ color: palette.dark.text }} />
            </IconButton>
            <IconButton
              sx={{ width: "40px", boxShadow: 2, borderRadius: 3 }}
              onClick={handleNavbarToggle}
            >
              <MenuIcon sx={{ color: palette.dark.text }} />
            </IconButton>
          </Toolbar>
        </AppBar>
      )}
    </Box>
  );
}

// Connect the component to Redux
export default Header;
