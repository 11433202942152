import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import { NFTCollectionProvider } from "./context/NFTCollectionContext";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { ThemeProvider } from "./context/themeContext.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <NFTCollectionProvider>
        <App />
      </NFTCollectionProvider>
    </ThemeProvider>
  </React.StrictMode>
);

reportWebVitals();
