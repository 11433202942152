// color design tokens export
import { createTheme } from "@mui/material/styles";

const breakpoints = {
  values: {
    xs: 0,
    sm: 700,
    md: 960,
    lg: 1280,
    xl: 2500,
  },
};

// mui theme settings
export const themeSettings = (tokens) => {
  return createTheme({
    palette: {
      // palette values for dark mode
      grey: {
        ...tokens.grey,
        main: tokens.grey[200],
      },
      primary: {
        ...tokens.primary,
        main: tokens.primary[100],
      },
      secondary: {
        ...tokens.secondary,
        main: tokens.secondary[100],
      },
      neutral: {
        ...tokens.grey,
      },
      background: {
        default: tokens.grey[100],
      },
      color: {
        default: tokens.secondary[500],
      },
      button: {
        default: "#fff",
        primary: tokens.text.primary,
        white: tokens.grey[200],
      },
      font: {
        default: tokens.text.default,
        primary: tokens.text.primary,
        white: tokens.grey[200],
      },
      dark: {
        text: tokens.dark.text,
        bg: tokens.dark.bg,
        disableLabel: tokens.dark.disableLabel,
        connect: tokens.dark.connect,
      },
    },
    // fontSize: 16,

    h1: {
      fontSize: "30px",

      [`@media (min-width:${breakpoints.values["sm"]}px)`]: {
        fontSize: "14px", // Example of responsive font size
      },
      [`@media (min-width:${breakpoints.values["md"]}px)`]: {
        fontSize: "16px", // Example of responsive font size
      },
      [`@media (min-width:${breakpoints.values["lg"]}px)`]: {
        fontSize: "20px", // Example of responsive font size
      },
      [`@media (min-width:${breakpoints.values["xl"]}px)`]: {
        fontSize: "26px", // Example of responsive font size
      },
    },
    // Define font sizes for other typography variants...

    breakpoints,
  });
};
