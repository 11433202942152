import React, { createContext, useState } from 'react';

export const NFTCollectionContext = createContext({
  ownedNFTs: [],
  setOwnedNFTs: () => {},
  updateNFTStatus: () => {},
});

export const NFTCollectionProvider = ({ children }) => {
  const [ownedNFTs, setOwnedNFTs] = useState([]);

  const updateNFTStatus = (tokenId, newStatus) => {
    setOwnedNFTs((currentNFTs) =>
      currentNFTs.map((nft) =>
        nft.tokenId === tokenId ? { ...nft, ...newStatus } : nft
      )
    );
  };

  return (
    <NFTCollectionContext.Provider
      value={{ ownedNFTs, setOwnedNFTs, updateNFTStatus }}
    >
      {children}
    </NFTCollectionContext.Provider>
  );
};
