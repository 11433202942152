import { Snackbar, SnackbarContent } from "@mui/material";
import html2canvas from "html2canvas";

import React from "react";
import instance from "../components/axios";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile } from "@ffmpeg/util";
const ffmpeg = new FFmpeg();

export function capitalizeFirstWord(str) {
  return str?.charAt(0).toUpperCase() + str?.slice(1);
}

export function showToast(type, message, setToast) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={true}
      onClose={setToast}
      message={message}
      key={message}
    />
  );
}
export const downloadFileFromUrl = async (tokenId, type, setProgress) => {
  try {
    const response = await instance
      .get("/files", {
        params: {
          tokenId,
          type,
        },
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          setProgress(progressEvent.loaded * 0.00000095367432);
        },

      })

    const fileNameExt = tokenId + "." + type;
    const blob = await new Blob([response.data], { type: response.headers['content-type'] })
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileNameExt; // Set file name for download
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

  } catch (err) {
    return err?.response?.data;
  }
};

export const getFileFromUrl = async (tokenId, type) => {
  try {
    const response = await instance.get("/files", {
      params: {
        tokenId,
        type,
      },
    });
    if (response?.data?.url) {
      return await response?.data?.url;
    }
  } catch (err) {
    return err?.response?.data;
  }
};

export const htmlToImageConvert = async (
  name,
  elementRef,
  setFullScreen = null,
  element = "#unityCanvas"
) => {
  try {
    const data = await html2canvas(document.querySelector(element), {
      allowTaint: true,
      useCORS: true,
      scale: 4,
    });
    var anchorTag = document.createElement("a");
    anchorTag.download = "ATS_" + name || "ATS-NFT-preview" + ".jpg";
    anchorTag.href = data.toDataURL();
    anchorTag.target = "_blank";
    anchorTag.click();
    if (setFullScreen) {
      exitFullscreen(elementRef, setFullScreen);
    }
  } catch (err) {
    console.log(err);
  }
};

export async function startRecording(
  id,
  fileName,
  setRecording,
  type,
  setPreviewContent,
  duration = 10,
  setConverting,
  downloadType
) {
  setRecording(true);
  try {
    const chunks = [];
    let canvasElt = document.getElementById(id);
    let canvasElt1 = canvasElt?.firstChild;
    const videoStream = canvasElt1.captureStream();
    let combinedStream = null;
    combinedStream = new MediaStream([...videoStream.getVideoTracks()]);
    const rec = new MediaRecorder(combinedStream, {
      mimeType: "video/mp4",
    });
    rec.ondataavailable = (e) => chunks.push(e.data);
    rec.onstop = async (e) => {
      const blob = new Blob(chunks, { type: "video/mp4" });
      if (type === "onlyRecord") {
        setRecording(false);
        setPreviewContent(blob);
      } else {
        await convertAndExportVid(
          blob,
          fileName,
          setRecording,
          setConverting,
          downloadType
        );
      }
    };
    rec.start();
    setTimeout(() => {
      rec.stop();
    }, duration * 1000);
  } catch (err) {
    console.log(err);
  }
}

export async function convertAndExportVid(
  blob,
  fileName,
  setRecording,
  setConverting,
  downloadType
) {
  try {
    await ffmpeg.load();
    const data = await fetchFile(blob);
    await ffmpeg.writeFile("input.mp4", data);
    setRecording(false);
    setConverting(true);
    const scale = () => {
      switch (downloadType) {
        case "LowRes":
          return "scale=1280:720";
        case "HighRes":
          return "scale=1600:900";
        case "UltraHD":
          return "scale=3840:2160";
      }
    };
    await ffmpeg.exec([
      "-i",
      "input.mp4",
      "-codec",
      "copy", // Speed up conversion
      "-crf",
      "15",
      "-ar",
      "44100",
      "-c:v",
      "libx264", // H.264 codec
      "-pix_fmt",
      "yuv420p", // Ensure QuickTime compatibility
      "-vf",
      scale(), // Maintain resolution (adjust as needed)
      "-preset",
      "ultrafast", // Speed up conversion
      "-movflags",
      "faststart",
      "output.mp4",
    ]);

    const mp4Data = await ffmpeg.readFile("output.mp4");
    const mp4Blob = new Blob([mp4Data], { type: "video/mp4" });

    const url = URL.createObjectURL(mp4Blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "DashBO_" + fileName + ".mp4";
    a.click();
    setConverting(false);
    URL.revokeObjectURL(url);
  } catch (err) {
    console.log(err);
    setRecording(false);
  }
}

export function exportVid(blob, fileName, setRecording) {
  try {
    let vid = document.createElement("video");
    vid.src = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.download = fileName + ".mp4";
    a.href = vid.src;
    // a.textContent = fileName;
    a.click();
    setRecording(false);
  } catch (err) {
    console.log(err);
  }
}
export const exitFullscreen = (elementRef, setFullScreen) => {
  if (elementRef.current) {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      // IE/Edge
      document.msExitFullscreen();
    }
    setFullScreen(false);
  }
};
export const handleFullscreen = (elementRef) => {
  if (elementRef.current) {
    if (elementRef.current.requestFullscreen) {
      elementRef.current.requestFullscreen();
    } else if (elementRef.current.mozRequestFullScreen) {
      // Firefox
      elementRef.current.mozRequestFullScreen();
    } else if (elementRef.current.webkitRequestFullscreen) {
      // Chrome, Safari, and Opera
      elementRef.current.webkitRequestFullscreen();
    } else if (elementRef.current.msRequestFullscreen) {
      // IE/Edge
      elementRef.current.msRequestFullscreen();
    }
  }
};
