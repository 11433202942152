import React from "react";
import Typewriter from "typewriter-effect";

import "./TypingEffect.css"; // Import the CSS file

export const TypingEffect = ({ text }) => {
  const typingSpeed = 100;
  return (
    <div className="typewriter">
      <Typewriter
        options={{
          strings: text,
          autoStart: true,
          cursor: "",
          delay: typingSpeed,
        }}
      />
    </div>
  );
};
