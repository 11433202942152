export const CONTAINERATS = "https://storage.googleapis.com/3d-container/ATS/";

export const homeBanner = CONTAINERATS + "home-banner.webp";
export const homeBannerDark = CONTAINERATS + "home-banner-dark.webp";

export const library =
  "https://storage.googleapis.com/3d-container/ATS/library.png";
export const file = "https://storage.googleapis.com/3d-container/ATS/file.png";
export const still =
  "https://storage.googleapis.com/3d-container/ATS/still-pose.png";
export const animation =
  "https://storage.googleapis.com/3d-container/ATS/animation.png";
