import { useTheme } from "@emotion/react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ApeUnity from "../../3dModels/models/ApeUnity";

function Dashboard3d() {
  const { palette, ...theme } = useTheme();
  const isMobile = useMediaQuery("(max-width:599px)");

  const UnityUI = () => {
    const { id } = useParams();
    const [mode, setMode] = useState("animate");

    const [buttonType, setButtonType] = useState([
      {
        name: "Animation",
        selected: true,
        items: [
          {
            style: "LookAround",
            image:
              "https://storage.googleapis.com/3d-container/ATS/animations/LookAround.gif",
            selected: true,
          },
          {
            style: "Shielding",
            image:
              "https://storage.googleapis.com/3d-container/ATS/animations/Shielding.gif",
            selected: false,
          },
          {
            style: "Idle",
            image:
              "https://storage.googleapis.com/3d-container/ATS/animations/Idle.gif",
            selected: false,
          },
          {
            style: "LeftAttack",
            image:
              "https://storage.googleapis.com/3d-container/ATS/animations/LeftAttack.gif",
            selected: false,
          },
          {
            style: "Run",
            image:
              "https://storage.googleapis.com/3d-container/ATS/animations/Run.gif",
            selected: false,
          },
          {
            style: "Walk",
            image:
              "https://storage.googleapis.com/3d-container/ATS/animations/Walk.gif",
            selected: false,
          },
        ],
      },
      {
        name: "Environment",
        selected: false,
        items: [
          {
            style: "Baby Blue",
            image:
              "https://storage.googleapis.com/3d-container/ATS/env/BabyBlue.jpg",
            selected: false,
          },
          {
            style: "Burnt Orange",
            image:
              "https://storage.googleapis.com/3d-container/ATS/env/BurntOrange.jpg",
            selected: false,
          },
          {
            style: "War on Apes",
            image:
              "https://storage.googleapis.com/3d-container/ATS/env/WaronApes.jpg",
            selected: false,
          },
          {
            style: "Hacker Grid",
            image:
              "https://storage.googleapis.com/3d-container/ATS/env/Hacker Grid.jpg",
            selected: false,
          },
          {
            style: "Lime Green",
            image:
              "https://storage.googleapis.com/3d-container/ATS/env/LimeGreen.jpg",
            selected: false,
          },
          {
            style: "Beige",
            image:
              "https://storage.googleapis.com/3d-container/ATS/env/Beige.jpg",
            selected: false,
          },

          {
            style: "Galaxy",
            image:
              "https://storage.googleapis.com/3d-container/ATS/env/Galaxy.jpg",
            selected: false,
          },
          {
            style: "Gold",
            image:
              "https://storage.googleapis.com/3d-container/ATS/env/Gold.jpg",
            selected: false,
          },
          {
            style: "Graphite",
            image:
              "https://storage.googleapis.com/3d-container/ATS/env/Graphite.jpg",
            selected: false,
          },
          {
            style: "Green",
            image:
              "https://storage.googleapis.com/3d-container/ATS/env/Green.jpg",
            selected: false,
          },

          {
            style: "Maroon",
            image:
              "https://storage.googleapis.com/3d-container/ATS/env/Maroon.jpg",
            selected: false,
          },
          {
            style: "Pink",
            image:
              "https://storage.googleapis.com/3d-container/ATS/env/Pink.jpg",
            selected: false,
          },
          {
            style: "Purple Clouds",
            image:
              "https://storage.googleapis.com/3d-container/ATS/env/PurpleClouds.jpg",
            selected: false,
          },
          {
            style: "Teal",
            image:
              "https://storage.googleapis.com/3d-container/ATS/env/Teal.jpg",
            selected: false,
          },
          {
            style: "Navy Blue",
            image:
              "https://storage.googleapis.com/3d-container/ATS/env/NavyBlue.jpg",
            selected: false,
          },
        ],
      },
    ]);
    const [modelJson, setModelJson] = useState({});
    const oneOnOne = [
      1855, 2001, 2052, 2406, 2567, 2865, 352, 4008, 4096, 4439, 1229,
    ];
    const cleanJsonValues = (singleObject) => {
      const cleanedObject = {};
      for (const key in singleObject) {
        if (singleObject.hasOwnProperty(key)) {
          const value = singleObject[key].replace(/\s+/g, " ").trim();
          if (
            (key === "Weapons" &&
              (value === "ATS_Weapon_WoodenStaff_GEO" ||
                value === "Sceptre")) ||
            oneOnOne.includes(parseInt(id))
          ) {
            setButtonType([
              {
                name: "Animation",
                selected: true,
                items: [
                  {
                    style: "LookAround",
                    image:
                      "https://storage.googleapis.com/3d-container/ATS/animations/LookAround.gif",
                    selected: true,
                  },
                  {
                    style: "Shielding",
                    image:
                      "https://storage.googleapis.com/3d-container/ATS/animations/Shielding.gif",
                    selected: false,
                  },
                  {
                    style: "Idle",
                    image:
                      "https://storage.googleapis.com/3d-container/ATS/animations/Idle.gif",
                    selected: false,
                  },
                  {
                    style: "RightAttack",
                    image:
                      "https://storage.googleapis.com/3d-container/ATS/animations/RightAttack.gif",
                    selected: false,
                  },
                  {
                    style: "Run",
                    image:
                      "https://storage.googleapis.com/3d-container/ATS/animations/Run.gif",
                    selected: false,
                  },
                  {
                    style: "Walk",
                    image:
                      "https://storage.googleapis.com/3d-container/ATS/animations/Walk.gif",
                    selected: false,
                  },
                ],
              },
              {
                name: "Environment",
                selected: false,
                items: [
                  {
                    style: "Baby Blue",
                    image:
                      "https://storage.googleapis.com/3d-container/ATS/env/BabyBlue.jpg",
                    selected: false,
                  },
                  {
                    style: "Burnt Orange",
                    image:
                      "https://storage.googleapis.com/3d-container/ATS/env/BurntOrange.jpg",
                    selected: false,
                  },
                  {
                    style: "War on Apes",
                    image:
                      "https://storage.googleapis.com/3d-container/ATS/env/WaronApes.jpg",
                    selected: false,
                  },
                  {
                    style: "Hacker Grid",
                    image:
                      "https://storage.googleapis.com/3d-container/ATS/env/Hacker Grid.jpg",
                    selected: false,
                  },
                  {
                    style: "Lime Green",
                    image:
                      "https://storage.googleapis.com/3d-container/ATS/env/LimeGreen.jpg",
                    selected: false,
                  },
                  {
                    style: "Beige",
                    image:
                      "https://storage.googleapis.com/3d-container/ATS/env/Beige.jpg",
                    selected: false,
                  },

                  {
                    style: "Galaxy",
                    image:
                      "https://storage.googleapis.com/3d-container/ATS/env/Galaxy.jpg",
                    selected: false,
                  },
                  {
                    style: "Gold",
                    image:
                      "https://storage.googleapis.com/3d-container/ATS/env/Gold.jpg",
                    selected: false,
                  },
                  {
                    style: "Graphite",
                    image:
                      "https://storage.googleapis.com/3d-container/ATS/env/Graphite.jpg",
                    selected: false,
                  },
                  {
                    style: "Green",
                    image:
                      "https://storage.googleapis.com/3d-container/ATS/env/Green.jpg",
                    selected: false,
                  },

                  {
                    style: "Maroon",
                    image:
                      "https://storage.googleapis.com/3d-container/ATS/env/Maroon.jpg",
                    selected: false,
                  },
                  {
                    style: "Pink",
                    image:
                      "https://storage.googleapis.com/3d-container/ATS/env/Pink.jpg",
                    selected: false,
                  },
                  {
                    style: "Purple Clouds",
                    image:
                      "https://storage.googleapis.com/3d-container/ATS/env/PurpleClouds.jpg",
                    selected: false,
                  },
                  {
                    style: "Teal",
                    image:
                      "https://storage.googleapis.com/3d-container/ATS/env/Teal.jpg",
                    selected: false,
                  },
                  {
                    style: "Navy Blue",
                    image:
                      "https://storage.googleapis.com/3d-container/ATS/env/NavyBlue.jpg",
                    selected: false,
                  },
                ],
              },
            ]);
          }
          cleanedObject[key] = value;
        }
      }
      return cleanedObject;
    };
    const getModelJson = async () => {
      const response = await fetch(
        `https://storage.googleapis.com/3d-container/ATS/trait_data/ATS_Metadata_v7.json`
      );

      const metaArray = await response.json();
      const selectedJson = await metaArray.find((element) => element.ID == id);
      const cleanedObject = cleanJsonValues(selectedJson);
      if (cleanedObject) {
        delete cleanedObject.ID;
        delete cleanedObject.Animal;
        setModelJson(cleanedObject);
      } else {
        alert(`NFT with This id ${id} Not Found `);
        setModelJson({});
      }
    };
    useEffect(() => {
      getModelJson();
    }, []);
    const RenderItems = ({ items }) => {
      return (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",

            alignItems: "left",
            justifyContent: "flex-start",
            [theme.breakpoints.down("sm")]: {
              flexWrap: "nowrap",
              overflowX: "scroll",
            },
          }}
        >
          {items.map((item) => {
            return (
              <Box
                onClick={() => {
                  const updated = items.map((it) => {
                    if (it.style === item.style) {
                      return {
                        ...it,
                        selected: true,
                      };
                    }
                    return {
                      ...it,
                      selected: false,
                    };
                  });
                  let arr = buttonType;
                  if (mode === "animate") {
                    arr[0].items = updated;
                  } else {
                    arr[1].items = updated;
                  }
                  setButtonType([...arr]);
                }}
                sx={{
                  cursor: "pointer",
                  border: "#301254",
                  background: "rgba(255, 255, 255, 0.3)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backdropFilter: "blur(8px)",
                  padding: "7px",
                  width: "30%",
                  margin: "7px",
                  borderRadius: "5px",
                  [theme.breakpoints.down("sm")]: {
                    width: "100px",
                  },
                }}
              >
                <img
                  style={{ width: isMobile ? "80px" : "6vw" }}
                  src={item.image}
                  alt="ats animation"
                />
              </Box>
            );
          })}
        </Box>
      );
    };
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          // padding: 3,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            // mt: "20px",
            justifyContent: "center",
          },
        }}
      >
        <ApeUnity
          mode={"animate"}
          id={id}
          modelJson={modelJson}
          animation={buttonType[0].items.find((e) => e.selected)?.style}
          environment={buttonType[1].items.find((e) => e.selected)?.style}
        />
        <Box
          sx={{
            width: "30%",
            position: "absolute",
            top: 28,
            right: 30,
            height: "80vh",
            borderRadius: "1vw",
            display: "flex",
            background: "rgba(0, 0, 0, 0.1)",
            backdropFilter: "blur(8px)",
            flexDirection: "column",
            paddingInline: "20px",
            border: "2px solid #fff",
            overflowX: "hidden",
            alignItems: "center",
            [theme.breakpoints.down("sm")]: {
              position: "relative",
              width: "100%",
              height: "35%",
              top: 0,
              border: "none",
              right: 0,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              paddingBlock: "20px",
              justifyContent: "space-around",
            }}
          >
            {buttonType.map((button, i) => {
              return (
                <ProjectButton project={button} mode={mode} setMode={setMode} />
              );
            })}
          </Box>
          <RenderItems
            items={
              mode === "animate" ? buttonType[0].items : buttonType[1].items
            }
          />
        </Box>
      </Box>
    );
  };

  return (
    <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <Box
        sx={{
          border: "2px solid #fff",
          height: "85vh",
          // marginTop: "-20px",
          position: "absolute",
          borderRadius: "0.5vw",
          bottom: "20px",
          width: "96%",
          marginInline: "auto",
          overflow: "hidden",
          [theme.breakpoints.down("sm")]: {
            position: "relative",
            mt: 10,
            marginInline: "auto",
            borderRadius: "10px",
            height: "100vh",
          },
        }}
      >
        <UnityUI />
      </Box>
    </Box>
  );
}

export default Dashboard3d;

const ProjectButton = ({ project, mode, setMode }) => {
  const theme = useTheme();
  const isSelected =
    project.name === "Environment"
      ? project.name === "Environment" && mode === "env"
      : project.name === "Animation" && mode === "animate";
  return (
    <Box
      sx={{
        cursor: "pointer",
      }}
      onClick={() => {
        setMode(project.name === "Environment" ? "env" : "animate");
      }}
    >
      <Box
        sx={{
          width: "10vw",
          border: "1px solid #fff",
          borderRadius: "10px",
          height: "2.5vw",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          background: isSelected ? "#EABE59" : "#ffff",
          [theme.breakpoints.down("sm")]: {
            width: "90%",
            marginBottom: "0px",
            maxWidth: "150px",
            minWidth: "120px",
            height: "35px",
            borderRadius: "8px",
          },
        }}
      >
        <Typography
          sx={{
            fontWeight: "700",
            fontFamily: "var(--font-f_r)",
            fontSize: "1vw",
            textTransform: "capitalize",
            textAlign: "center",
            color: isSelected ? "#fff" : "#000",
            [theme.breakpoints.down("lg")]: {
              fontSize: "16px",
            },
            [theme.breakpoints.down("sm")]: {
              fontSize: "12px",
            },
          }}
        >
          {project.name}
        </Typography>
      </Box>
    </Box>
  );
};
